import React from 'react';
import styles from './Specification.module.css';

function Specifications(props) {

  function gcd(a,b) {
    a = Math.abs(a);
    b = Math.abs(b);
    if (b > a) {var temp = a; a = b; b = temp;}
    while (true) {
        if (b == 0) return a;
        a %= b;
        if (a == 0) return b;
        b %= a;
    }
  }

  return (
    <div className={styles.specificationsContainer}>
      <div className={styles.specContainer}>
        <span className={styles.specName}>Components</span>
        <div className={styles.specInfo}>
          {Object.keys(props.requiredFiles).map(function (fileName, keyIndex) {
            return (
              <div key={keyIndex}>
                <div>
                <strong>{fileName}.{props.requiredFiles[fileName].file_type}</strong>
                <ul>
                {props.requiredFiles[fileName].dimensions.check_exact_dimensions === false ?
                  <>
                    <li>Required aspect ratio:<span> </span>
                    {props.requiredFiles[fileName].dimensions.width/gcd(
                      props.requiredFiles[fileName].dimensions.width, props.requiredFiles[fileName].dimensions.height
                      )}
                      :
                      {props.requiredFiles[fileName].dimensions.height/gcd(
                      props.requiredFiles[fileName].dimensions.width, props.requiredFiles[fileName].dimensions.height
                      )}
                      </li>
                      <li>Recommended dimensions: {props.requiredFiles[fileName].dimensions.width} x {props.requiredFiles[fileName].dimensions.height} px</li>
                 </>
                :
                  <>
                    <li>Required dimensions: {props.requiredFiles[fileName].dimensions.width} x {props.requiredFiles[fileName].dimensions.height} px</li>
                  </>
                }
                <li>Max file size: {props.requiredFiles[fileName].max_file_size} KB</li>
                {props.requiredFiles[fileName].file_type == 'mp4' && 
                <li>Max duration: {props.requiredFiles[fileName].max_duration} seconds</li>
                }
                </ul>
                </div>
                <div>
                </div>

              </div>
            );
          })}
        </div>
      </div>

    </div>
  );
}

export default Specifications;
