import React from 'react';
import AccordionElement from './AccordionElement';
import styles from './Faq.module.css';

function Faq(props) {
  return (
    <div>
      <h2 className={styles.faqHeader}>FAQ</h2>
      {props.faqData.map((qa, index) => {
        return (
          <AccordionElement
            key={index}
            question={qa.question}
            answer={qa.answer}
          />
        );
      })}
    </div>
  );
}

export default Faq;
