import React, { useState } from 'react';

import { Switch, Route, NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import update from 'immutability-helper';

import styles from './ContactPageDataEdit.module.css';

function ContactPageDataEdit(props) {
    return (
        <div className={styles.homePageDataFormContainer}>

            <div className={styles.inputContainer}>
                <div className={styles.inputContainer}>
                    <label htmlFor={'formatId'}>ID</label>
                    <input value={props.contactPageData._id} name={'formatId'} id={'formatId'} disabled></input>
                </div>

                <div className={styles.inputContainer}>
                    <label htmlFor={'dataLocation'}>Page</label>
                    <input value={props.contactPageData.data_location} name={'dataLocation'} id={'dataLocation'} disabled></input>
                </div>

            </div>

            <h3>Business units:</h3>
            <div className={styles.businessUnitsContainer}>
                {props.contactPageData.business_units.map((country, i) => {
                    return (            
                        <div className={styles.businessUnitContainer} key={i}>
                            {/* <h3>Template example:</h3> */}
                            <div className={styles.inputGroup}>
                                <div>
                                    <label>English country name</label>
                                    <input value={country.country_english}
                                        onChange={(e) => {
                                            props.setDefaultData((prevValue) => (
                                                update(prevValue, { contact_page: { business_units: { [i]: { country_english: { $set: e.target.value } } } } })
                                            ));
                                        }}></input>
                                </div>

                                <div>
                                    <label>Country name native</label>
                                    <input value={country.country_native}
                                        onChange={(e) => {
                                            props.setDefaultData((prevValue) => (
                                                update(prevValue, { contact_page: { business_units: { [i]: { country_native: { $set: e.target.value } } } } })
                                            ));
                                        }}></input>
                                </div>
                            </div>

                            <div className={styles.inputGroup}>
                                <div>
                                    <label>Country Shorthand</label>
                                    <input value={country.country_shorthand}
                                        onChange={(e) => {
                                            props.setDefaultData((prevValue) => (
                                                update(prevValue, { contact_page: { business_units: { [i]: { country_shorthand: { $set: e.target.value } } } } })
                                            ));
                                        }}></input>
                                </div>

                                <div>
                                    <label>email</label>
                                    <input value={country.email}
                                        onChange={(e) => {
                                            props.setDefaultData((prevValue) => (
                                                update(prevValue, { contact_page: { business_units: { [i]: { email: { $set: e.target.value } } } } })
                                            ));
                                        }}></input>
                                </div>
                            </div>

                            <div className={styles.examplesRemoveContainer}>
                                <div className={styles.iconButtonContainer}>
                                    <i className="fas fa-minus" onClick={
                                        () => {

                                            const removeIndex = props.contactPageData.business_units.indexOf(country)
                                            props.setDefaultData((prevValue) => (
                                                update(prevValue, { contact_page: { business_units: { $splice: [[removeIndex, 1]] } } })
                                            ));
                                        }
                                    }></i>
                                </div>
                            </div>
                        </div>
                    )
                })}

                <div
                    className={styles.addElementField}
                    onClick={
                        () => {
                            (props.contactPageData.business_units.map(unit => Object.values(unit).includes('')).every(element => element === false) &&
                                props.setDefaultData((prevValue) => (
                                    update(prevValue, {
                                        contact_page: {
                                                business_units: {
                                                    $push: [{
                                                        country_english: "",
                                                        country_native: "",
                                                        country_shorthand: "",
                                                        email: "",
                                                    }]
                                                }
                                        }
                                    })
                                )))
                        }
                    }
                >
                    <div className={styles.iconButtonContainer}>
                        <i className="fas fa-plus"></i>
                    </div>

                </div>

            </div>


        </div>

    );
}
export default ContactPageDataEdit;
