import React, { useState, useRef } from 'react';
import styles from './TemplateFilesManagement.module.css';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import update from 'immutability-helper';
import ConfirmationButton from '../../components/ConfirmationButton/ConfirmationButton';

import axios from 'axios';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

function TemplateFilesManagement(props) {
  const { search } = useLocation();
  const { template } = queryString.parse(search);

  const [files, setFiles] = useState(null);

  const [unpackZip, setUnpackZip] = useState(true);

  // Set the target folder option to the first option
  const [targetFolder, setTargetFolder] = useState(`templates/${template}`);

  const [acceptedFilesTarget, setAcceptedFilesTarget] = useState('preview_templates');

  const acceptedMimeTypes = [
    'application/x-zip-compressed',
    'application/x-compressed',
    'application/zip',
    'multipart/x-zip',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'video/mp4',
    'text/css',
    'text/html',
    'text/javascript',
    'application/pdf',
  ];

  const acceptedFileTypes = {
    preview_templates: ['.zip', '.html', '.js', '.css', '.mp4'],
    templates: ['.zip', '.html', '.js', '.css', '.mp4'],
    assets: ['.zip', '.jpg', '.jpeg', '.png', '.mp4', '.mp3'],
    template_example_assets: ['.zip', '.jpg', '.jpeg', '.png', '.mp4', '.mp3'],
    template_downloads: ['.pdf', '.zip'],
  };

  const fileInputRef = useRef();

  async function downloadFile(fileLocation, fileName) {
    const newLocation = fileLocation.replace(/\//g, ',');

    const res = await axios.get(process.env.REACT_APP_HOST + `/serve-template-files/${newLocation}/${fileName}`, {
      responseType: 'arraybuffer',
    });

    const blob = new Blob([res.data]);

    saveAs(blob, fileName);
  }

  function onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    fileUpload(files).then((response) => {
      // Set selected files back to null to prevent re-submission
      setFiles(null);
      // Make input empty again,
      // to allow submitting the same file incase of changes
      fileInputRef.current.value = null;

      console.log(response);
      if (response.data.succes) {
        console.log('succesful upload');
      } else {
        console.log('something went wrong during upload');
      }
      if (response?.data?.succes) {
        // Call for files again
        Promise.all([axios.get(process.env.REACT_APP_HOST + '/get-file-data')])
          .then(([templateFileData]) => {
            props.setTemplateFilesData(templateFileData.data);
          })
          .catch((err) => {
            throw err;
          });
      }
    });
  }
  function fileInputOnChange(e) {
    setFiles(e.target.files);
    // Reset selected files to allow reselecting
    // the same file in case changes were made
  }

  function fileUpload(files) {
    const url = process.env.REACT_APP_HOST + '/upload-template-files';
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      /* Check if singular files are part of the accepted ones,
               Or if the file is a zip
            */

      const splitFile = files[i].name.split('.');
      const [fileName, fileExtension] = splitFile;

      // Check if file has a potential double file extension
      if (splitFile.length === 3) {
        // Make sure it's a double file extension and warn the user
        if (splitFile[1] === splitFile[2]) {
          toast.warn(`${files[i].name} might have a double file extension.`);
        }
      }

      if (acceptedMimeTypes.includes(files[i].type)) {
        formData.append('zipFile', files[i]);
      }
    }
    // If any of the files are okay send a request
    if (formData.get('zipFile')) {
      formData.append('templateName', props.selectedFormat);
      formData.append('fileFolder', targetFolder);
      formData.append('sessionId', sessionStorage.getItem('sessionId'));
      formData.append('unpackZip', unpackZip);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      return axios.post(url, formData, config);
    } else {
      return Promise.resolve('No valid files submitted');
    }
  }

  const handleOnDragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragExit = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setFiles(e.dataTransfer.files);
  };

  async function deleteFile(fileLocation, index) {
    const url = process.env.REACT_APP_HOST + '/remove-template-file';

    const response = await axios.post(url, { fileLocation: fileLocation });

    if (response.data.succes) {
      const fileLocationSplit = fileLocation.split('/');
      if (fileLocationSplit.includes('preview_templates')) {
        if (fileLocationSplit.includes('assets')) {
          props.setTemplateFilesData((prevValue) => update(prevValue, { preview_templates: { [template]: { assets: { $splice: [[index, 1]] } } } }));
        } else {
          props.setTemplateFilesData((prevValue) => update(prevValue, { preview_templates: { [template]: { root_files: { $splice: [[index, 1]] } } } }));
        }
      } else if (fileLocationSplit.includes('template_downloads')) {
        props.setTemplateFilesData((prevValue) => update(prevValue, { template_downloads: { [template]: { $splice: [[index, 1]] } } }));
      } else if (fileLocationSplit.includes('template_example_assets')) {
        props.setTemplateFilesData((prevValue) => update(prevValue, { template_example_assets: { [template]: { $splice: [[index, 1]] } } }));
      } else {
        props.setTemplateFilesData((prevValue) => update(prevValue, { templates: { [template]: { root_files: { $splice: [[index, 1]] } } } }));
      }
    }
  }

  return (
    <div className={styles.filesDataFormContainer}>
      <form
        onSubmit={onFormSubmit}
        onDragOver={props.selectedFormat && handleOnDragOver}
        onDrop={props.selectedFormat && handleOnDrop}
        onDragEnter={props.selectedFormat && handleOnDragEnter}
        onDragLeave={props.selectedFormat && handleOnDragExit}
        className={styles.uploadForm}
      >
        <div className={styles.formButtonsContainer}>
          <label htmlFor="folder-select" className={styles.formHeader}>
            Choose which templates folder you want to upload to:
          </label>

          <select
            name="folder-select"
            id="folder-select"
            value={targetFolder}
            onChange={(e) => {
              setTargetFolder(e.target.value);
              if (e.target.value.includes('assets')) {
                setAcceptedFilesTarget('assets');
              } else {
                setAcceptedFilesTarget(e.target.value.split('/')[0]);
              }
            }}
          >
            {/* {Object.keys(props.templateFilesData).map((folderName, i) => {
                            return <option key={i} value={`${folderName}/${template}/`}>{folderName.replace(/_/g, ' ')}</option>
                        })} */}
            <option value={`templates/${template}/`}>{'Template Files'}</option>
            <option value={`preview_templates/${template}/`}>{'Preview Template Files'}</option>
            <option value={`preview_templates/${template}/assets/`}>{'Preview Template Files (Assets)'}</option>

            <option value={`template_example_assets/${template}/`}>{'Template Examples Assets'}</option>
            <option value={`template_downloads/${template}/`}>{'Template Downloads'}</option>
          </select>

          <div className={styles.radioButtonContainer}>
            <span>Unpack uploaded zip files?</span>
            <div className={styles.buttonHolder}>
              <div>
                <label htmlFor="yes">Yes</label>
                <input type="radio" value={true} name="yes" checked={unpackZip === true} onChange={() => setUnpackZip(true)} />
              </div>

              <div>
                <label htmlFor="no">No</label>
                <input type="radio" value={false} name="no" checked={unpackZip === false} onChange={() => setUnpackZip(false)} />
              </div>
            </div>
          </div>

          <div className={styles.uploadButtonContainer}>
            <div className={styles.uploadButtonHolder}>
              <label htmlFor="fileInput" id="customFileInput" className={styles.customFileInput}>
                Select files
              </label>

              <input
                type="file"
                id="fileInput"
                className={styles.originalFileInput}
                name="fileInput"
                onChange={fileInputOnChange}
                accept={acceptedFileTypes[acceptedFilesTarget].toString()}
                multiple={true}
                ref={fileInputRef}
              />
            </div>

            <div className={styles.uploadButtonHolder}>
              <button type="submit" className={!files ? styles.uploadButton : styles.uploaded} disabled={!files}>
                Upload files
              </button>
            </div>
          </div>

          <div className={styles.uploadsContainer}>
            {files && (
              <div className={styles.uploadedFilesContainer}>
                Added file(s):{' '}
                {Object.values(files).map((file, index, arr) => {
                  return (
                    <span key={index}>
                      {file.name}
                      {arr.length - 1 === index ? null : ','}{' '}
                    </span>
                  );
                })}
              </div>
            )}
          </div>

          <p className={styles.uploadFilesText}>* Uploading files that already exist replaces them</p>
        </div>
      </form>

      <h2>Template files</h2>
      <div>
        <div className={styles.fileList}>
          {props.templateFilesData.templates[template].root_files.map((fileName, key) => {
            return (
              <div key={key} className={styles.filesContainers}>
                <div className={styles.fileButtonContainer}>
                  <span>{fileName}</span>
                  <ConfirmationButton buttonAction={() => deleteFile(`templates/${template}/${fileName}`, key)} buttonText={'Delete file'} />
                  <span
                    onClick={() => {
                      downloadFile(`templates/${template}`, fileName);
                    }}
                    className={`${styles.downloadButton}`}
                  >
                    Download
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <h2>Preview template files</h2>
      <div>
        <div className={styles.fileList}>
          {props.templateFilesData.preview_templates[template].root_files.map((fileName, key) => {
            return (
              <div key={key} className={styles.filesContainers}>
                <div className={styles.fileButtonContainer}>
                  <span>{fileName}</span>
                  <ConfirmationButton buttonAction={() => deleteFile(`preview_templates/${template}/${fileName}`, key)} buttonText={'Delete file'} />
                  <span
                    onClick={() => {
                      downloadFile(`preview_templates/${template}`, fileName);
                    }}
                    className={`${styles.downloadButton}`}
                  >
                    Download
                  </span>
                </div>
              </div>
            );
          })}
          <h3>Assets</h3>
          {props.templateFilesData.preview_templates[template].assets.map((fileName, key) => {
            return (
              <div key={key} className={styles.filesContainers}>
                <div className={styles.fileButtonContainer}>
                  <span>{fileName}</span>
                  <ConfirmationButton buttonAction={() => deleteFile(`preview_templates/${template}/assets/${fileName}`, key)} buttonText={'Delete file'} />
                  <span
                    onClick={() => {
                      downloadFile(`preview_templates/${template}/assets`, fileName);
                    }}
                    className={`${styles.downloadButton}`}
                  >
                    Download
                  </span>
                </div>
              </div>
            );
          })}

          <div>
            {/* Get the difference between the array with expected files, and the array with submitted files */}
            {props.templatesData.template_files &&
              !!Object.entries(props.templatesData.template_files)
                .map(([key, value]) => `${key}.${value.file_type}`)
                .filter((e) => !props.templateFilesData.preview_templates[template].assets.includes(e)).length && <p>Missing assets</p>}

            {Object.entries(props.templatesData.template_files)
              .map(([key, value]) => `${key}.${value.file_type}`)
              .filter((e) => !props.templateFilesData.preview_templates[template].assets.includes(e))
              .map((file, key) => {
                // Check if the required assets are uploaded already, if not show the file is needed
                return (
                  <div key={key} className={styles.filesContainers}>
                    <div className={styles.fileButtonContainer}>
                      <span>{file}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <h2>Example assets</h2>
      <div>
        <div className={styles.fileList}>
          {props.templateFilesData.template_example_assets[template].map((fileName, key) => {
            return (
              <div key={key} className={styles.filesContainers}>
                <div className={styles.fileButtonContainer}>
                  <span>{fileName}</span>
                  <ConfirmationButton buttonAction={() => deleteFile(`template_example_assets/${template}/${fileName}`, key)} buttonText={'Delete file'} />
                  <span
                    onClick={() => {
                      downloadFile(`template_example_assets/${template}`, fileName);
                    }}
                    className={`${styles.downloadButton}`}
                  >
                    Download
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <h2>Template downloads</h2>
      <div className={styles.fileList}>
        {props.templateFilesData.template_downloads[template].map((fileName, key) => {
          return (
            <div key={key} className={styles.filesContainers}>
              <div className={styles.fileButtonContainer}>
                <span>{fileName}</span>
                <ConfirmationButton buttonAction={() => deleteFile(`template_downloads/${template}/${fileName}`, key)} buttonText={'Delete file'} />
                <span
                  onClick={() => {
                    downloadFile(`template_downloads/${template}`, fileName);
                  }}
                  className={`${styles.downloadButton}`}
                >
                  Download
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TemplateFilesManagement;
