import React from 'react';
import styles from './StepTwo.module.css';
import navStyles from '../../components/Nav.module.css';
import Faq from '../../components/Tabs/Faq/Faq';

//eslint-disable-next-line
import DownloadLinksLarge from '../../components/DownLoadLinksLarge/DownloadLinksLarge';
import Specifications from '../../components/Tabs/Specification/Specifications';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  NavLink,
  Redirect,
} from 'react-router-dom';

import FileUpload from '../../components/Tabs/FileUpload/FileUpload';
import FileResults from '../../components/Tabs/FileResults/FileResults';

import { ToastContainer } from 'react-toastify';

function StepTwo(props) {
  const history = useHistory();

  const { path, url } = useRouteMatch();

  return (
    <div className={styles.pageTwo}>
      <div className={styles.tabContainer}>
        <div
          className={styles.backButton}
          onClick={() =>
            history.push(
              `/home/stepOne/introduction?format=${props.selectedFormat}`
            )
          }
        >
          <span className={styles.arrow}>
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className={styles.text}>Back</span>
        </div>
        <ul className={navStyles.tabList}>
          <li>
            <NavLink
              to={`${url}/upload-assets?format=${props.selectedFormat}`}
              className={navStyles.stepTabListItem}
              activeClassName={navStyles.active}
            >
              Upload Assets
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${url}/specifications?format=${props.selectedFormat}`}
              className={navStyles.stepTabListItem}
              activeClassName={navStyles.active}
            >
              Specifications
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${url}/faq?format=${props.selectedFormat}`}
              className={navStyles.stepTabListItem}
              activeClassName={navStyles.active}
            >
              FAQ
            </NavLink>
          </li>
        </ul>
      </div>

      <div className={styles.tabContent}>
        <h1>Upload your assets</h1>
        <div className={styles.selectedFormatBar}>
          <div className={styles.checkmarkContainer}>
            <i className="fas fa-check"></i>
          </div>
          <span className={styles.selectedFormat}>
            {props.templatesData[props.selectedFormat].long_template_name}
          </span>
        </div>
        <Switch>
          <Route exact path={path}>
            <Redirect
              from={path}
              to={`${path}/upload-assets?format=${props.selectedFormat}`}
            />
          </Route>
          <Route path={`${path}/upload-assets`}>
            <FileUpload
              selectedFormat={props.selectedFormat}
              formatRequirements={
                props.templatesData[props.selectedFormat].template_files
              }
              setCheckedFilesInfo={props.setCheckedFilesInfo}
              setPreviewWasViewed={props.setPreviewWasViewed}
              setCreativeLink={props.setCreativeLink}
              setCreativeZipLink={props.setCreativeZipLink}
            />
            <FileResults
              formatRequirements={
                props.templatesData[props.selectedFormat].template_files
              }
              selectedFormat={props.selectedFormat}
              checkedFilesInfo={props.checkedFilesInfo}
              setCreativeLink={props.setCreativeLink}
              creativeLink={props.creativeLink}
              previewWasViewed={props.previewWasViewed}
              setPreviewWasViewed={props.setPreviewWasViewed}
            />
            <ToastContainer
              position="bottom-center"
              autoClose={15000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
          </Route>
          <Route path={`${path}/specifications`}>
            <Specifications
              requiredFiles={
                props.selectedFormat
                  ? props.templatesData[props.selectedFormat].template_files
                  : undefined
              }
            />
          </Route>
          <Route path={`${path}/faq`}>
            <Faq
              faqData={
                props.selectedFormat
                  ? props.templatesData[props.selectedFormat].template_info.faq
                  : undefined
              }
            />
          </Route>
        </Switch>
      </div>

      <div className={styles.rightBar}>
        <DownloadLinksLarge
          inSideBar={true}
          selectedFormat={props.selectedFormat}
          instructionsPdf={
            props.templatesData[props.selectedFormat].template_info
              .templates_files_zip
          }
          templateFilesZip={
            props.templatesData[props.selectedFormat].template_info
              .instructions_pdf
          }
        />
      </div>
    </div>
  );
}

export default StepTwo;
