import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Switch, Route, NavLink, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import update from 'immutability-helper';

import styles from './Admin.module.css';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import UploadHistory from '../UploadHistory/UploadHistory';
import TemplateData from '../TemplateData/TemplateData';
import TemplateFiles from '../TemplateFiles/TemplateFiles';

function Admin(props) {
  sessionStorage.clear();
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', uuidv4());
  }
  // const { path, url } = useRouteMatch();
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const { template, page } = queryString.parse(search);
  const history = useHistory();

  const [templatesData, setTemplatesData] = useState({});
  const [defaultData, setDefaultData] = useState({});
  // The template files data

  const [loading, setLoading] = useState(true);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  function updateTemplateData() {
    let errorsFound = false;

    let templateData = templatesData[template];

    if (!templateData.long_template_name || !templateData.template_name) {
      errorsFound = true;
    }

    if (templateData.template_elements) {
      templateData.template_elements = templateData.template_elements.filter((element) => element !== '');
    }

    Object.entries(templateData.template_files).map(([fileName, prop]) => {
      // !prop.dimensions.check_exact_dimensions ||
      if (!prop.dimensions.width || !prop.dimensions.height || !prop.max_file_size) {
        errorsFound = true;
      }
    });

    if (templateData.template_info.bullet_points) {
      templateData.template_info.bullet_points = templateData.template_info.bullet_points.filter((element) => element !== '');
    }

    if (!templateData.template_info.description || !templateData.template_info.instructions_pdf || !templateData.template_info.templates_files_zip) {
      errorsFound = true;
    }

    if (!templateData.template_info.examples.map((example) => Object.values(example).includes('')).every((element) => element === false)) {
      errorsFound = true;
    }

    Object.entries(templateData.template_info.faq).map(([key, prop]) => {
      if (!prop.question || !prop.answer) {
        errorsFound = true;
      }
    });

    if (errorsFound) {
      toast.error(`Make sure all required fields have a value`);
    } else {
      const url = process.env.REACT_APP_HOST + '/update-template-data';
      toast.success('Succes');
      return axios.post(url, { old_template_name: template, new_template_data: templateData }).then(function (result) {
        // If the name of the template got changed
        if (template !== templateData.template_name) {
          // Add new data with new key to dataset
          setTemplatesData((prevValue) => update(prevValue, { $merge: { [templateData.template_name]: result.data[0] } }));
          history.replace(`/admin/template-data/template?template=${templateData.template_name}`);

          // Remove data with old key
          setTemplatesData((prevValue) => update(prevValue, { $unset: [template] }));
        } else {
          // If not just replace the data
          setTemplatesData((prevValue) => update(prevValue, { [templateData.template_name]: { $set: result.data[0] } }));
        }
      });
    }
  }

  function updateContactPageData() {
    let errorsFound = false;

    let contactPageData = defaultData.contact_page;

    contactPageData.business_units.forEach((unit) => {
      if (isBlank(unit.country_english) || isBlank(unit.country_native) || isBlank(unit.country_shorthand) || isBlank(unit.email)) {
        errorsFound = true;
      }
    });

    if (errorsFound) {
      toast.error(`Make sure all required fields have a value`);
    } else {
      const url = process.env.REACT_APP_HOST + '/update-default-data';
      toast.success('Succes');
      // return axios.post(url, templateData);
    }
  }

  function updateHomePageData() {
    let errorsFound = false;

    let homePageData = defaultData.home_page;

    if (isBlank(homePageData.description)) {
      errorsFound = true;
    }

    if (homePageData.bullet_points.length <= 0) {
      errorsFound = true;
    }

    if (errorsFound) {
      toast.error(`Make sure all required fields have a value`);
    } else {
      const url = process.env.REACT_APP_HOST + '/update-default-data';
      toast.success('Succes');
      // return axios.post(url, templateData);
    }
  }

  if (loading) {
    setLoading(false);
    Promise.all([axios.get(process.env.REACT_APP_HOST + '/get-all-template-and-page-data')])
      .then(([templateData]) => {
        setTemplatesData(templateData.data.templatesData);
        setDefaultData(templateData.data.defaultData);
      })
      .catch((err) => {
        throw err;
      });
  }

  const pages = [
    {
      iconClass: 'fas fa-file-upload',
      pageTitle: 'Uploads History',
      pageLink: 'upload-history',
    },
    {
      iconClass: 'fas fa-align-left',
      pageTitle: 'Template Data',
      pageLink: 'template-data',
    },
    {
      iconClass: 'far fa-folder-open',
      pageTitle: 'Template Files',
      pageLink: 'template-files',
    },
  ];

  return (
    <div className={styles.adminPage}>
      <Header />

      <div className={styles.adminPageContainer}>
        <div className={styles.stepNavigation}>
          <h1>Easy Rich Media</h1>
          {pages.map((pageObject, i) => {
            return (
              <NavLink
                to={`${url}/${pageObject.pageLink}`}
                className={`${styles.stepContainer}`}
                activeClassName={styles.active}
                key={i}
                // onClick={() => handleOnClick(key)}
              >
                <div className={styles.iconContainer}>
                  <span>
                    <i className={pageObject.iconClass}></i>
                  </span>
                </div>
                <div className={styles.textContainer}>
                  <span className={styles.text}>{pageObject.pageTitle}</span>
                </div>
              </NavLink>
            );
          })}
        </div>
        <div className={styles.adminPageContent}>
          {loading ? (
            <div className={styles.loadingIcon}></div>
          ) : (
            <Switch>
              <Route path={'/admin/upload-history'}>
                <UploadHistory />
              </Route>

              <Route path={'/admin/template-data'}>
                <TemplateData
                  templatesData={templatesData}
                  setTemplatesData={setTemplatesData}
                  defaultData={defaultData}
                  updateTemplateData={updateTemplateData}
                  setDefaultData={setDefaultData}
                  updateContactPageData={updateContactPageData}
                  updateHomePageData={updateHomePageData}
                />
              </Route>
              <Route path={'/admin/template-files'}>
                <TemplateFiles templatesData={templatesData}></TemplateFiles>
              </Route>
            </Switch>
          )}
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
}

export default Admin;
