import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

function Header(props) {
  return (
    <header className={`${styles.header} ${isMobile && styles.mobile}`}>
      <div className={styles.headerContent}>
        <Link
          to="/home/stepOne/introduction/"
          onClick={() => {
            sessionStorage.clear();
            sessionStorage.setItem('sessionId', uuidv4());
          }}
        >
          <img src={isMobile ? '/logos/icon-filled.png' : '/logos/logo-white.png'} alt="weborama logo" className={styles.logo} />
        </Link>
      </div>
      {isMobile && <h1 className={styles.ezRichMediaheader}>Easy Rich Media</h1>}
    </header>
  );
}

export default Header;
