import React, { useEffect, useState } from 'react';
import styles from './DownloadLinksLarge.module.css';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { saveAs } from 'file-saver';

function DownloadLinksLarge(props) {
//  `${process.env.REACT_APP_HOST}/email-data/${props.sessionId}`
  async function downloadFile(templateName, fileName) {
    const res = await axios.get(process.env.REACT_APP_HOST + `/serve-download-files/${templateName}/${fileName}`, {
      responseType: 'arraybuffer'
    });

    const blob = new Blob([res.data])

    saveAs(blob, fileName);
  }

  return (
    <div
      className={
        props.inSideBar
          ? styles.downloadLinksSidebarContainer
          : styles.downloadLinksContainer
      }
    >
      {props.inSideBar && <h2 className={styles.h2}>downloads</h2>}
      <div
        className={
          props.inSideBar ? styles.linkSidebarContainer : styles.linkContainer
        }
      >
        <a
          // eslint-disable-next-line
          onClick={() => {
            downloadFile(props.selectedFormat, props.templateFilesZip)
            }}
        >
          <button className={styles.downloadLinksLargeButton}>
            <p> Download Instructions</p>
            <p className={styles.buttonTextSmall}>
              {props.selectedFormat.replace(/_/g, ' ')} Instructions (.pdf)
            </p>
          </button>
        </a>
      </div>

      <div className={styles.linkContainer}>
        <a
          // eslint-disable-next-line
          onClick={() => {
            downloadFile(props.selectedFormat, props.instructionsPdf)
            }}
        >
          <button className={styles.downloadLinksLargeButton}>
            <p>Download Template</p>
            <p className={styles.buttonTextSmall}>
              {`${props.selectedFormat.replace(/_/g, ' ')}`} template (.zip)
            </p>
          </button>
        </a>
      </div>
    </div>
  );
}

export default DownloadLinksLarge;
