import React, { useState } from 'react';
import {
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useLocation,
  Redirect,
  useHistory
} from 'react-router-dom';
import queryString from 'query-string';
import update from 'immutability-helper';
import axios from 'axios';

import Modal from 'react-modal';

import styles from './TemplateData.module.css';
import navStyles from '../../components/Nav.module.css';

import HomePageDataEdit from '../HomePageDataEdit/HomePageDataEdit';
import ContactPageDataEdit from '../ContactPageDataEdit/ContactPageDataEdit';

// eslint-disable-next-line
import TemplateFilesDataForm from '../../components/TemplateFilesDataForm/TemplateFilesDataForm';
import TemplateExamplesDataForm from '../../components/TemplateExamplesDataForm/TemplateExamplesDataForm';
import ConfirmationButton from '../../components/ConfirmationButton/ConfirmationButton';

function TemplateData(props) {
  const { path, url } = useRouteMatch();
  const { search } = useLocation();
  const { template, page } = queryString.parse(search);

  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  Modal.setAppElement('#root');

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  async function deleteTemplateHandler() {
    try {
      await axios.post(
        `${process.env.REACT_APP_HOST}/delete-template`, {"template_name": template, template_id: props.templatesData[template]._id}
      );
      closeModal();
      // props.templatesData
      props.setTemplatesData((prevValue) => (
        update(prevValue, { $unset: [template] } )
      ));
      history.replace(`/admin/template-data/template`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.TemplateDataContainer}>
      <div className={styles.templateDataContent}>
        <div className={styles.tabContainer}>
          <h2>Template Data</h2>
          <ul className={navStyles.tabList}>

            {Object.keys(props.templatesData).sort().map((docName, key) => {
              return (
                <li key={key}>
                  <NavLink
                    to={`${url}/template?template=${docName}`}
                    className={navStyles.stepTabListItem}
                    activeClassName={navStyles.active}
                    isActive={() => template == docName}
                  >
                    {docName.replace(/_/g, ' ')}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <NavLink
                to={`${url}/template?template=new_template`}
                className={navStyles.stepTabListItem}
                activeClassName={navStyles.active}
              >
                <div
                  className={styles.addElementField}
                  onClick={
                    () => {
                      (!Object.keys(props.templatesData).includes('new_template') &&
                        props.setTemplatesData((prevValue) => (
                          {
                            ...prevValue, "new_template": {
                              "_id": "",
                              "template_name": "",
                              "long_template_name": "",
                              "template_elements": [

                              ],
                              "template_files": {
                                "": {
                                  "template_element": [
                                    ""
                                  ],
                                  "file_type": "png",
                                  "dimensions": {
                                    "check_exact_dimensions": false,
                                    "width": 0,
                                    "height": 0
                                  },
                                  "max_file_size": 0
                                }
                              },
                              "template_info": {
                                "templates_files_zip": "",
                                "instructions_pdf": "",
                                "description": "",
                                "bullet_points": [
                                  ""
                                ],
                                "examples": [
                                  {
                                    "advertiser": "",
                                    "campaign": "",
                                    "thumbnail_file_name": "",
                                    "video_url": "",
                                    "preview_url": ""
                                  }
                                ],
                                "faq": [
                                  {
                                    "question": "",
                                    "answer": ""
                                  }
                                ]
                              }
                            }
                          })
                        ))
                    }
                  }
                >
                  <div className={styles.iconButtonContainer}>
                    <i className="fas fa-plus"></i>
                  </div>

                </div>
              </NavLink>
            </li>
          </ul>
          <h2>Default Data</h2>
          <ul className={navStyles.tabList}>
            {Object.keys(props.defaultData).map((docName, key) => {
              return (
                <li key={key}>
                  <NavLink
                    to={`${url}/${docName}`}
                    className={navStyles.stepTabListItem}
                    activeClassName={navStyles.active}
                    isActive={() => template == docName}
                  >
                    {docName.replace(/_/g, ' ')}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.tabContent}>
          <Switch>
            {(template && props.templatesData[template]) && (
              <Route path={'/admin/template-data/template'}>

                <TemplateFilesDataForm
                  dataSet={props.templatesData[template]}
                  setTemplatesData={props.setTemplatesData} />

                <TemplateExamplesDataForm
                  dataSet={props.templatesData[template].template_info}
                  setTemplatesData={props.setTemplatesData} />

                <input type="button" value="Save" onClick={props.updateTemplateData}></input>
                <input type="button" value="Delete Template" disabled={!(props.templatesData[template]._id)} className={styles.deleteTemplateButton} onClick={() => {
                  openModal();
                }}></input>

                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  closeTimeoutMS={500}
                  contentLabel="Delete Template Modal"
                  className={styles.modal}
                  overlayClassName={{
                    base: styles.overlayBase,
                    afterOpen: styles.overlayAfterOpen,
                    beforeClose: styles.overLaybeforeClose,
                  }}>

                  <div>
                    <span className={styles.closeButton} onClick={closeModal}>
                      <i className="fas fa-times"></i>
                    </span>
                    <div className={styles.spacingSmall}>
                      <h2>Are you sure you want to delete <span className='templateName'>{template}</span>?</h2>
                      <div>
                        Deleting this template will delete the following:
                        <ul>
                          <li>All file data in the database associated with this template.</li>
                          <li>All template files used for previewing.</li>
                          <li>All template files used for eventual previewing.</li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.ConfirmationButtonContainer}>
                      <ConfirmationButton
                        buttonAction={deleteTemplateHandler}
                        buttonText={`Confirm ${template} deletion.`}
                      />
                    </div>
                  </div>
                </Modal>

              </Route>
            )}

            <Route path={'/admin/template-data/home_page'}>
              <HomePageDataEdit
                homePageData={props.defaultData.home_page}
                setDefaultData={props.setDefaultData}
              />
              <input type="button" value="Save" onClick={props.updateHomePageData}></input>
            </Route>
            <Route path={'/admin/template-data/contact_page'}>
              <ContactPageDataEdit
                contactPageData={props.defaultData.contact_page}
                setDefaultData={props.setDefaultData}
              />
              <input type="button" value="Save" onClick={props.updateContactPageData}></input>
            </Route>

          </Switch>
        </div>
      </div>
    </div>
  );
}

export default TemplateData;