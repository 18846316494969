import React from 'react';
import styles from './StepFour.module.css';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function StepFour(props) {
  const history = useHistory();

  function handleLastStep() {
    history.push('/');
  }

  return (
    <div className={styles.pageFour}>
      <div className={styles.content}>
        <h1>Thank you for your campaign</h1>
        <div className={styles.contactText}>We will contact you shortly</div>
        <input
          type="button"
          onClick={() => handleLastStep()}
          value="Build your next creative"
        />
        <div className={styles.backButton} onClick={() => handleLastStep()}>
          <span className={styles.arrow}>
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className={styles.text}>Back to homepage</span>
        </div>
      </div>
    </div>
  );
}

export default StepFour;
