import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './FileUpload.module.css';

function FileUpload(props) {
  const [files, setFiles] = useState(null);
  const wrongFileType = [];
  const wrongFile = [];
  const doubleExtension = [];

  const fileInputRef = useRef();

  function createErrorMessage() {
    let error = '';
    if (wrongFile.length !== 0) {
      error += `Unknown files:\n${wrongFile.join(',\n')}\n\n`;
    }
    if (wrongFileType.length !== 0) {
      error += `Wrong file extension:\n${wrongFileType.join(',\n')}`;
    }
    if (doubleExtension.length !== 0) {
      error += `Duplicate file extension:\n${doubleExtension}`
    }
    wrongFileType.length = 0;
    wrongFile.length = 0;
    doubleExtension.length = 0;
    return error;
  }

  function onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    fileUpload(files).then((response) => {
      const errorMessage = createErrorMessage();
      if (typeof response === 'string') {
        toast.error(`All files rejected\n\n${errorMessage}`);
      } else {
        if (errorMessage === '') {
          if (response.data?.message == 'No valid files found in upload') {
            toast.warn(response.data?.message);
          } else {
            toast.success('Succes');
          }
        } else {
          toast.warn(`The following files were rejected:\n\n${errorMessage}`);
        }

        if (response.data.previewLink) {
          props.setCreativeLink((prevValue) => ({
            ...prevValue,
            [props.selectedFormat]: `http://${response.data.previewLink}`,
          }));

          props.setCreativeZipLink((prevValue) => ({
            ...prevValue,
            [props.selectedFormat]: `http://${response.data.zipLink}`,
          }));
        }

        // Set the preview was viewed to false when new materials are uploaded
        props.setPreviewWasViewed((prevValue) => ({
          ...prevValue,
          [props.selectedFormat]: false,
        }));

        props.setCheckedFilesInfo((prevValue) => ({
          ...prevValue,
          [props.selectedFormat]: response.data.checkedFileInfo,
        }));
      }

      // Set selected files back to null to prevent re-submission
      setFiles(null);
      // Make input empty again,
      // to allow submitting the same file incase of changes
      fileInputRef.current.value = null;
    });
  }
  function fileInputOnChange(e) {
    setFiles(e.target.files);
    // Reset selected files to allow reselecting
    // the same file in case changes were made
  }

  function fileUpload(files) {
    const url = process.env.REACT_APP_HOST + '/upload';
    const formData = new FormData();

    const allowedFileNames = [];
    for (const [fileName, fileInfo] of Object.entries(
      props.formatRequirements
    )) {
      allowedFileNames.push(`${fileName}.${fileInfo.file_type}`);
    }

    for (let i = 0; i < files.length; i++) {
      /* Check if singular files are part of the accepted ones,
         Or if the file is a zip
      */

      const splitFile = files[i].name.split('.');
      const [fileName, fileExtension] = splitFile;

      // Check if file has a potential double file extension
      if (splitFile.length === 3) {
        // Make sure it's a double file extension and warn the user
        if (splitFile[1] === splitFile[2]) {
          doubleExtension.push(`${files[i].name}`)
        }
      }

      if (
        files[i].type === 'application/x-zip-compressed' ||
        files[i].type === 'application/x-compressed' ||
        files[i].type === 'application/zip' ||
        files[i].type === 'multipart/x-zip'
      ) {
        formData.append('zipFile', files[i]);
      } else if (Object.keys(props.formatRequirements).includes(fileName)) {
        if (fileExtension === props.formatRequirements[fileName].file_type) {
          formData.append('zipFile', files[i]);
        } else {
          wrongFileType.push(
            // eslint-disable-next-line max-len
            `${fileName}.${fileExtension} should be a ${props.formatRequirements[fileName].file_type}`
          );
        }
      } else {
        wrongFile.push(`${fileName}.${fileExtension}`);
      }
    }

    // If any of the files are okay send a request
    if (formData.get('zipFile')) {
      formData.append('templateName', props.selectedFormat);
      formData.append('sessionId', sessionStorage.getItem('sessionId'));
      // eslint-disable-next-line max-len
      // Pass the currently used format data, If data happened to be updated between someone opening the site and sending the files this will prevent and error by not using the name data in the back-end
      formData.append(
        'templateRequirements',
        JSON.stringify(props.formatRequirements)
      );

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      return axios.post(url, formData, config);
    } else {
      return Promise.resolve('No valid files submitted');
    }
  }

  const handleOnDragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDragExit = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOnDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setFiles(e.dataTransfer.files);
  };

  return (
    <div className={styles.uploadContainer}>
      <form
        onSubmit={onFormSubmit}
        onDragOver={props.selectedFormat && handleOnDragOver}
        onDrop={props.selectedFormat && handleOnDrop}
        onDragEnter={props.selectedFormat && handleOnDragEnter}
        onDragLeave={props.selectedFormat && handleOnDragExit}
        className={styles.uploadForm}
      >
        <h2>Drag & drop your assets here</h2>
        <span className={styles.selectFilesText}>
          Or browse and select your files (jpg, png) or ZIP-file.
        </span>
        {files && (
          <div className={styles.uploadedFilesContainer}>
            Added file(s):{' '}
            {Object.values(files).map((file, index, arr) => {
              return (
                <span key={index}>
                  {file.name}
                  {arr.length - 1 === index ? null : ','}{' '}
                </span>
              );
            })}
          </div>
        )}

        <div className={styles.formButtonsContainer}>
          <label
            htmlFor="fileInput"
            id="customFileInput"
            className={styles.customFileInput}
          >
            Select files
          </label>

          <input
            type="file"
            id="fileInput"
            className={styles.originalFileInput}
            name="fileInput"
            onChange={fileInputOnChange}
            accept=".zip, .png, .jpg, .jpeg, .mp4"
            multiple={true}
            disabled={!props.selectedFormat}
            ref={fileInputRef}
          />
          <button
            type="submit"
            className={!files ? styles.uploadButton : styles.uploaded}
            disabled={!files}
          >
            Upload assets
          </button>
        </div>
        <p className={styles.uploadFilesText}>
          * Please make sure you upload all of the required files and do not
          change the file names.
        </p>
      </form>
    </div>
  );
}

export default FileUpload;
