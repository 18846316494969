import React, { useState } from 'react';
import styles from './StepThree.module.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function StepThree(props) {
  const history = useHistory();

  const [editingForm, setEditingForm] = useState(true);
  const [formValid, setFormValid] = useState(true);
  const [downloaded, setDownloaded] = useState(false);

  function getConfirmationScreen() {
    const requiredFields = {
      'Campaign name': props.campaignName.value,
      'Template name': props.selectedFormat,
      'Main contact name': props.contactName.value,
      'E-mail': props.email.value,
      'CC emails': props.ccEmails,
      'Business unit': props.businessUnit.country_english,
      'Landing page URLs': props.landingPageURLs,
      'Additional information': props.comment,
      'Re-check creative': (
        <a href={props.creativeLink} target="_blank" rel="noreferrer">
          Click here for preview (new tab)
        </a>
      ),
    };

    return (
      <div>
        {Object.keys(requiredFields).map((fieldName, index) => {
          return (
            <div key={index} className={styles.confirmationInfoContainer}>
              <div className={styles.infoName}>{fieldName}</div>
              <div className={styles.infoValue}>
                {fieldName === 'CC emails' || fieldName === 'Landing page URLs'
                  ? requiredFields[fieldName].map((object, i) => {
                    return <div key={i}>{object.value}</div>;
                  })
                  : requiredFields[fieldName]}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function handleCcEmails(event, index) {
    // Use slice to get a copy of the array, instead of a reference
    const newValues = props.ccEmails.slice();

    newValues[index].value = event.target.value;

    props.setCcEmails(newValues);
  }

  function handleLandingPages(event, index) {
    // Use slice to get a copy of the array, instead of a reference
    const newValues = props.landingPageURLs.slice();

    newValues[index].value = event.target.value;

    props.setLandingPageURLs(newValues);
  }

  function onFormSubmit(e) {
    e.preventDefault(); // Stop form submit

    /* Ignore the warning about unnecessary escape characters in
       email regex (Only works on the line directly after the comment). */

    const emailRegex =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const urlRegex =
    // eslint-disable-next-line
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    let formPassedChecks = true;

    ['campaignName', 'contactName', 'email', 'ccEmails', 'landingPageURLs'].forEach(
      (inputName) => {
        switch (inputName) {
          case 'campaignName':
            if (!props.campaignName.value || props.campaignName.value.length < 3) {
              props.setCampaignName((prevValue) => ({
                ...prevValue,
                valid: false,
              }));
              formPassedChecks = false;
            } else {
              props.setCampaignName((prevValue) => ({
                ...prevValue,
                valid: true,
              }));
            }
            break;
          case 'contactName':
            if (!props.contactName.value || props.contactName.value.length < 2) {
              props.setContactName((prevValue) => ({
                ...prevValue,
                valid: false,
              }));
              formPassedChecks = false;
            } else {
              props.setContactName((prevValue) => ({
                ...prevValue,
                valid: true,
              }));
            }
            break;
          case 'ccEmails': {
            const newValues = props.ccEmails;

            props.ccEmails.forEach((object, i) => {
              if (!emailRegex.test(object.value) && object.value !== '' && object.value.length) {
                newValues[i].valid = false;
                props.setCcEmails(newValues);

                formPassedChecks = false;
              } else {
                newValues[i].valid = true;
                props.setCcEmails(newValues);
              }
            });

            break;
          }
          case 'landingPageURLs': {
            const newValues = props.landingPageURLs;

            props.landingPageURLs.forEach((object, i) => {
              if (!urlRegex.test(object.value) && object.value !== '' && object.value.length) {
                newValues[i].valid = false;
                props.setLandingPageURLs(newValues);
                formPassedChecks = false;
              } else {
                newValues[i].valid = true;
                props.setLandingPageURLs(newValues);
              }
            });
            break;
          }
          case 'email':
            if (!emailRegex.test(props.email.value)) {
              props.setEmail((prevValue) => ({ ...prevValue, valid: false }));

              formPassedChecks = false;
            } else {
              props.setEmail((prevValue) => ({ ...prevValue, valid: true }));
            }
            break;
          default:
        }
      }
    );

    if (formPassedChecks) {
      setFormValid(true);
      setEditingForm(false);
    }
  }

  function sendEmail() {
    const mailObject = {
      to: process.env.REACT_APP_HOST == 'https://eztemplates-api.weborama.nl' ? props.businessUnit.email : process.env.REACT_APP_RECIPIENT_EMAIL,
      // eslint-disable-next-line
      subject: props.campaignName.value,
      content: {
        user: props.contactName.value,
        email: props.email.value,
        template: props.selectedFormat,
        // eslint-disable-next-line
        previewUrl: props.creativeLink.replace(/\\/g, '/'),
      },
    };

    const ccEmailValues = props.ccEmails
      .filter((ccEmailObject) => ccEmailObject.value !== '')
      .map((ccEmailObject) => ccEmailObject.value);

    if (ccEmailValues.length > 0) {
      mailObject.content.cc = ccEmailValues;
    }

    const landingPageURLValues = props.landingPageURLs
      .filter((landingPageObject) => landingPageObject.value !== '')
      .map((landingPageObject) => landingPageObject.value);

    if (landingPageURLValues.length > 0) {
      mailObject.content.landingpages = landingPageURLValues;
    }

    if (props.comment !== '') {
      mailObject.content.comment = props.comment;
    }

    axios
      .post(process.env.REACT_APP_HOST + '/create_and_send_zip', {
        sessionId: sessionStorage.getItem('sessionId'),
        templateName: props.selectedFormat,
        mailObject: mailObject,
      })
      .then(function (response) {
        // weborama is notified correctly so
        // clear the session
        sessionStorage.clear();
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        history.push('/home/stepFour');
        // always executed
      });
  }

  return (
    <div className={styles.pageTwo}>
      <div className={styles.backButtonContainer}>
        <div
          className={styles.backButton}
          onClick={() => {
            editingForm === false ? setEditingForm(true) : history.goBack();
          }}
        >
          <span className={styles.arrow}>
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className={styles.text}>Back</span>
        </div>
      </div>

      <div className={styles.tabContent}>
        {/* eslint-disable-next-line */}
        {editingForm ? (
          <div className={styles.mailFormContainer}>
            <h1>Campaign & contact details</h1>
            <h2>
              Please fill in your campaign details and contact information.
            </h2>
            {!formValid && (
              <span className={styles.missingFieldText}>
                Please complete the missing form fields
              </span>
            )}

            <form onSubmit={() => onFormSubmit()} noValidate>
              <div
                className={`${styles.inputContainer} ${!props.campaignName.valid && styles.invalid
                  }`}
              >
                <label htmlFor="campaign">Campaign name</label>
                <input
                  id="campaign"
                  type="text"
                  name="campaign"
                  value={props.campaignName.value}
                  onChange={(e) => {
                    props.setCampaignName((prevValue) => ({
                      ...prevValue,
                      value: e.target.value,
                    }));
                  }}
                  maxLength="128"
                  required
                />
              </div>

              <div className={styles.nameAndMailContainer}>
                <div
                  className={`${styles.inputContainer} ${!props.contactName.valid && styles.invalid
                    }`}
                >
                  <label htmlFor="user">Main contact name</label>
                  <input
                    id="user"
                    type="text"
                    name="user"
                    value={props.contactName.value}
                    onChange={(e) => {
                      props.setContactName((prevValue) => ({
                        ...prevValue,
                        value: e.target.value,
                      }));
                    }}
                    required
                    maxLength="64"
                  />
                </div>

                <div
                  className={`${styles.inputContainer} ${!props.email.valid && styles.invalid
                    }`}
                >
                  <label htmlFor="email">E-mail</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={props.email.value}
                    onChange={(e) => {
                      props.setEmail((prevValue) => ({
                        ...prevValue,
                        value: e.target.value,
                      }));
                    }}
                    required
                    maxLength="92"
                  />
                </div>
              </div>

              <div
                id="ccFieldsContainer"
                className={`${styles.ccFieldsContainer} ${!props.ccEmails.every((value) => value.valid === true) &&
                  styles.invalid
                  }`}
              >
                <div className={styles.ccFieldsInputsContainer}>
                  <label htmlFor="ccMail">Cc</label>

                  {props.ccEmails.map((ccEmailObject, i) => {
                    return (
                      <input
                        key={i}
                        type="email"
                        name="ccMails"
                        value={ccEmailObject.value}
                        className={`${!ccEmailObject.valid && styles.invalid}`}
                        onChange={(e) => handleCcEmails(e, i)}
                        maxLength="92"
                      />
                    );
                  })}
                </div>

                <div className={styles.addCcFieldButtonContainer}>
                  <div
                    className={styles.addCcField}
                    onClick={() =>
                      props.setCcEmails((prevValue) => [
                        ...prevValue,
                        { value: '', valid: true },
                      ])
                    }
                  >
                    <i className="fas fa-plus"></i>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.selectInputContainer} ${!props.campaignName.valid && styles.invalid
                  }`}
              >
                <label htmlFor="business-unit">Business unit</label>
                <select
                  id="business-unit"
                  type="text"
                  name="business-unit"
                  className={styles.selectInput}
                  value={props.defaultData.business_units.findIndex(object => {
                    return object.country_shorthand === props.businessUnit.country_shorthand
                  })}
                  onChange={(e) => {
                    props.setBusinessUnit(props.defaultData.business_units[e.target.value])
                  }}
                  required>

                  {props.defaultData.business_units.map((businessUnit, i) => {
                    return (
                      <option
                        key={i}
                        value={i}
                      >{businessUnit.country_english}</option>
                    );
                  })}

                </select>

              </div>

              <div
                id="landingPageFieldsContainer"
                className={`${styles.landingPageFieldsContainer} ${!props.landingPageURLs.every((value) => value.valid === true) &&
                  styles.invalid
                  }`}
              >
                <div className={styles.landingPageInputsContainer}>
                  <label htmlFor="landingURLs">Landing page URLs</label>

                  {props.landingPageURLs.map((LandingPageObject, i) => {
                    return (
                      <input
                        key={i}
                        type="url"
                        name="landingURLs"
                        value={LandingPageObject.value}
                        className={`${!LandingPageObject.valid && styles.invalid}`}
                        onChange={(e) => handleLandingPages(e, i)}
                        maxLength="256"
                      />
                    );
                  })}
                </div>

                <div className={styles.addLandingPageFieldButtonContainer}>
                  <div
                    className={styles.addLandingPageField}
                    onClick={() =>
                      props.setLandingPageURLs((prevValue) => [
                        ...prevValue,
                        { value: '', valid: true },
                      ])
                    }
                  >
                    <i className="fas fa-plus"></i>
                  </div>
                </div>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="comment">Additional information</label>
                <textarea
                  id="comment"
                  name="comment"
                  placeholder="Example: URL of landingpage(s),
                   Campaign info: brand, campaign, startdate,
                   buying platform (DSP), buying agency."
                  value={props.comment}
                  onChange={(e) => {
                    props.setComment(e.target.value);
                  }}
                  maxLength="400"
                ></textarea>
              </div>

              <div className={styles.buttonsContainer}>
                <button
                  type="submit"
                  name="submit"
                  id="mailSubmitButton"
                  onClick={(e) => onFormSubmit(e)}
                  className={styles.checkCampaignButton}
                >
                  Check Campaign
                </button>
                <span>or</span>
                <a
                  name="download"
                  id={styles.downloadCampaignButton}
                  href={props.creativeZipLink && props.creativeZipLink.replace('http', 'https')}
                  className={styles.downloadCampaignButton}
                  download
                  onClick={(e) => {
                    if (!downloaded) {
                      axios
                        .post(process.env.REACT_APP_HOST + '/set-downloaded', {
                          sessionId: sessionStorage.getItem('sessionId'),
                        }).then(function (response) {
                          setDownloaded(true);
                        })
                        .catch(function (error) {
                          console.log(error);
                        })
                    }
                  }
                  }
                >
                  Download Campaign
                </a>
                
              </div>
            </form>
          </div>
        ) : (
          <div className={styles.submittedInfoContainer}>
            <h1>Confirm Information</h1>
            <h2>Please check your campaign details and contact information.</h2>

            <div>
              {getConfirmationScreen()}

              <div className={styles.confirmationInfoContainer}>
                <div
                  className={`${styles.flex} ${styles.editInfoButton}`}
                  onClick={() => setEditingForm(true)}
                >
                  Edit information
                </div>
                <div className={styles.submitButton} onClick={sendEmail}>
                  Notify Weborama
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.rightBar}></div>
    </div>
  );
}

export default StepThree;
