import React, { useState, useEffect } from 'react';
import styles from './ViewMailModal.module.css';
import Modal from 'react-modal';
import axios from 'axios';
import { useHistory } from 'react-router';
// eslint-disable-next-line
import ConfirmationButton from '../../components/ConfirmationButton/ConfirmationButton';

export default function ViewMailModal(props) {
  const [emailData, setEmailData] = useState(null);
  const history = useHistory();
  Modal.setAppElement('#root');

  useEffect(() => {
    const getEmailData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST}/email-data/${props.sessionId}`
        );
        setEmailData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.modalIsOpen) {
      getEmailData();
    }
  }, [props.modalIsOpen, props.sessionId]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    props.setModalIsOpen(false);
    history.goBack();
  }

  const deleteEmailHandler = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_HOST}/email-data/${props.sessionId}`
      );
      setEmailData(null);
      props.setModalIsOpen(false);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        contentLabel="Email Modal"
        className={styles.modal}
        overlayClassName={{
          base: styles.overlayBase,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overLaybeforeClose,
        }}
      >
        {emailData ? (
          <div>
            <span className={styles.closeButton} onClick={closeModal}>
              Close
              <i className="fas fa-times"></i>
            </span>
            <div className={styles.spacingSmall}>
              <h2>Previewer Notification by {emailData.content.user}</h2>

              <div><span className={styles.key}>From:</span>{emailData.content.email}</div>

              <div><span className={styles.key}>CC:</span> {emailData.content.cc ? emailData.content.cc.join(', ') : '-'}</div>

              <div><span className={styles.key}>Reason for notification:</span> New materials</div>

              <div><span className={styles.key}>Template used in creation:</span> {emailData.content.template}</div>

              <div><span className={styles.key}>Campaign name:</span> {emailData.subject}</div>

              <div><span className={styles.key}>Creative preview:</span> <a
                rel="noreferrer"
                target="_blank"
                href={emailData.content.previewUrl}
              >
                {emailData.content.previewUrl}
              </a></div>


              <div>
                <span className={styles.key}>Landing pages:</span>
                <ul>
                {emailData.content.landingpages.map((url, i) => {
                  return <li key={i}>{url}</li>
                })}
                </ul>
              </div>

              <div><span className={styles.key}>Comments: </span>
                {emailData.content.comment
                  ? emailData.content.comment
                  : "User didn't leave any comment. "}</div>

              <div><span className={styles.key}>Download URL: </span>
                <a rel="noreferrer" target="_blank" href={emailData.content.path}>
                  {emailData.content.path}
                </a></div>

            </div>
            <div className={styles.margin}>
              <ConfirmationButton
                buttonAction={deleteEmailHandler}
                buttonText={'Delete Email'}
              />
            </div>
          </div>
        ) : (
          <>
            <span className={styles.closeButton} onClick={closeModal}>
              Close
              <i className="fas fa-times"></i>
            </span>
            <p>Sorry, no email was found</p>
          </>
        )}
      </Modal>
    </div>
  );
}
