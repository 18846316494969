import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
// eslint-disable-next-line
import ConfirmationButton from '../../components/ConfirmationButton/ConfirmationButton';
import ViewMailModal from '../../components/viewMailModal/ViewMailModal';
import styles from './UploadHistory.module.css';

function UploadHistory(props) {
  const [uploadHistory, setUploadHistory] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emails, setEmails] = useState(null);
  const { path } = useRouteMatch();
  const history = useHistory();

  async function fetchUploadHistory() {
    try {
      const response = await axios.get(process.env.REACT_APP_HOST + '/upload-history');
      setUploadHistory(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchEmailData() {
    try {
      axios.get(`${process.env.REACT_APP_HOST}/email-data`).then((response) => {
        setEmails(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchUploadHistory();
    fetchEmailData();
  }, []);

  const togglePinned = async (sessionId, templateName) => {
    try {
      await axios.post(process.env.REACT_APP_HOST + '/set_pinned:sessionId', {
        sessionId: sessionId,
        templateName: templateName,
      });
      fetchUploadHistory();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFolder = (sessionId, templateName) => {
    axios
      .post(process.env.REACT_APP_HOST + '/delete_folder:sessionId', {
        sessionId: sessionId,
        templateName: templateName,
      })
      .then(function (response) {
        setUploadHistory((prevValue) => {
          return prevValue.filter((folderInfo) => folderInfo.id !== sessionId);
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  const showViewEmailButton = (folderInfoId, folderTemplate) => {
    const emailsFiltered = emails.filter((email) => {
      return email.sessionId === folderInfoId && email.content.template === folderTemplate;
    });
    if (emailsFiltered.length) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.uploadsContainer}>
      <table className={styles.uploadHistoryTable}>
        <thead>
          <tr className={styles.resultsHeaders}>
            <th className={styles.validity}>Pinned</th>
            <th className={styles.validity}>Validity</th>
            <th className={styles.downloaded}>Downloaded</th>
            <th className={styles.templateName}>Template Name</th>
            <th className={styles.templateId}>Id</th>
            <th className={styles.creationDate}>Creation time</th>
            <th className={styles.actions}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {uploadHistory
            ?.sort((a, b) => {
              const dateA = new Date(a.timeStamp);
              const dateB = new Date(b.timeStamp);
              return dateB - dateA;
            })
            .map((folderInfo, index) => {
              return (
                <tr className={styles.resultsContainer} key={index}>
                  <td className={styles.pinned}>
                    {folderInfo.pinned ? (
                      <FontAwesomeIcon icon={faLock} onClick={() => togglePinned(folderInfo.id, folderInfo.templateName)} size="lg" color="#5d7997" />
                    ) : (
                      <FontAwesomeIcon icon={faLockOpen} onClick={() => togglePinned(folderInfo.id, folderInfo.templateName)} size="lg" color="#5d7997" />
                    )}
                  </td>
                  <td className={styles.validity}>
                    <div className={styles.validityIconContainer}>
                      <i className={folderInfo.valid ? `fas fa-check ${styles.checkMark}` : `fas fa-times ${styles.Cross}`}></i>
                    </div>
                  </td>
                  <td className={styles.downloaded}></td>
                  <td className={styles.templateName}>{folderInfo.templateName.replaceAll('_', ' ')}</td>
                  <td className={styles.templateId}>{folderInfo.id}</td>
                  <td className={styles.creationDate}>{folderInfo.creationDateString}</td>
                  <td className={styles.actions}>
                    <div>
                      <a
                        href={
                          folderInfo.previewLink &&
                          // eslint-disable-next-line
                          `${process.env.REACT_APP_HOST}/${folderInfo.previewLink}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={`${styles.button} ${styles.previewButton}
                        ${!folderInfo.previewLink && styles.disabled}`}
                      >
                        Preview
                      </a>
                      <a
                        href={folderInfo.zipLink && `${process.env.REACT_APP_HOST}/${folderInfo.zipLink}`}
                        className={`
                 ${styles.button}
                 ${styles.downloadButton}
                 ${!folderInfo.zipLink && styles.disabled}`}
                      >
                        Download
                      </a>
                      <ConfirmationButton buttonAction={() => deleteFolder(folderInfo.id, folderInfo.templateName)} buttonText={'Delete'} />
                      {emails && showViewEmailButton(folderInfo.id, folderInfo.templateName) ? (
                        <button
                          className={`
                    ${styles.button}
                    ${styles.viewMailButton}`}
                          onClick={() => {
                            history.push(`${path}/${folderInfo.id}`);
                            setModalIsOpen(!modalIsOpen);
                          }}
                        >
                          view mail
                        </button>
                      ) : (
                        <div className={styles.emptySpace}></div>
                      )}

                      <Switch>
                        <Route path={`${path}/${folderInfo.id}`}>
                          <ViewMailModal sessionId={folderInfo.id} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                        </Route>
                      </Switch>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default UploadHistory;
