import React, { useState } from 'react';

import { Switch, Route, NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import update from 'immutability-helper';

import styles from './HomePageDataEdit.module.css';

function HomePageDataEdit(props) {
    return (
        <div className={styles.homePageDataFormContainer}>

            <div className={styles.inputContainer}>
                <div className={styles.inputContainer}>
                    <label htmlFor={'formatId'}>ID</label>
                    <input value={props.homePageData._id} name={'formatId'} id={'formatId'} disabled></input>
                </div>

                <div className={styles.inputContainer}>
                    <label htmlFor={'dataLocation'}>Page</label>
                    <input value={props.homePageData.data_location} name={'dataLocation'} id={'dataLocation'} disabled></input>
                </div>

            </div>

            <h3>Description</h3>
            <textarea value={props.homePageData.description}
                className={styles.descriptionInput}
                onChange={(e) => {
                    props.setDefaultData((prevValue) => (
                        update(prevValue, { home_page: { description: { $set: e.target.value } } })
                    ));
                }}
            ></textarea>

            <h3>Bullet points</h3>
            <div className={styles.bulletPointsContainer}>
                {props.homePageData.bullet_points.map((bulletPoint, i) => {
                    return (
                        <div key={i} className={styles.bulletPointContainer}>
                            <input value={bulletPoint}
                                onChange={(e) => {
                                    props.setDefaultData((prevValue) => (
                                        update(prevValue, { home_page: { bullet_points: { [i]: { $set: e.target.value } } } })
                                    ));
                                }}
                            ></input>

                            <div className={styles.iconButtonContainer}>
                                <i className="fas fa-minus" onClick={
                                    () => {
                                        const removeIndex = props.homePageData.bullet_points.indexOf(bulletPoint)
                                        props.setDefaultData((prevValue) => (
                                            update(prevValue, { home_page: { bullet_points: { $splice: [[removeIndex, 1]] } } })
                                        ));
                                    }
                                }></i>
                            </div>
                        </div>
                    )
                })}

                <div
                    className={styles.addElementField}
                    onClick={
                        () => {
                            (!props.homePageData.bullet_points.includes('') &&
                                props.setDefaultData((prevValue) => (
                                    update(prevValue, { home_page: { bullet_points: {  $push: [''] } } })
                                )))
                        }
                    }
                >
                    <div className={styles.iconButtonContainer}>
                        <i className="fas fa-plus"></i>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default HomePageDataEdit;
