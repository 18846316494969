import React, { useState } from 'react';
import styles from './AccordionElement.module.css';
import AnimateHeight from 'react-animate-height';

function AccordionElement(props) {
  const [selectOpen, setSelectOpen] = useState(false);

  return (
    <div className={styles.accordionDiv}>
      <div
        className={styles.accordionButton}
        onClick={() => {
          setSelectOpen((prevState) => !prevState);
        }}
      >
        <span className={styles.accordionButtonText}>{props.question}</span>
        <span
          className={`${styles.accordionButtonArrow} ${
            selectOpen === true ? styles.active : null
          }`}
        >
          <i className="fas fa-chevron-down"></i>
        </span>
      </div>

      <AnimateHeight duration={500} height={selectOpen ? 'auto' : 0}>
        <div
          className={`${styles.accordionContent} ${
            selectOpen === true ? styles.open : null
          }`}
        >
          {props.answer}
        </div>
      </AnimateHeight>
    </div>
  );
}

export default AccordionElement;
