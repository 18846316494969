import React, { useEffect } from 'react';
import styles from './StickyBar.module.css';
import { useHistory, useLocation } from 'react-router-dom';

function StickyBar(props) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      const scrolled = document.scrollingElement.scrollTop;

      if (location.pathname.split('/')[2] === 'stepOne') {
        if (scrolled >= 100 && props.modalIsOpen === false) {
          props.setStickyBarActive(true);
        } else {
          props.setStickyBarActive(false);
        }
      }
    });
  });

  return (
    <div
      className={`${styles.stickyBar} ${
        props.stickyBarActive && styles.active
      }`}
    >
      <div className={styles.stickyBarContent}>
        <div
          className={styles.backButton}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <span className={styles.arrow}>
            <i className="fas fa-arrow-up"></i>
          </span>
          <span className={styles.text}>Back to top</span>
        </div>

        <button
          type="button"
          onClick={() => {
            history.push(`/home/stepTwo?format=${props.selectedFormat}`);
            props.setStickyBarActive(false);
          }}
          className={styles.selectTemplateButton}
          disabled={!props.selectedFormat}
        >
          select this template
        </button>
      </div>
    </div>
  );
}

export default StickyBar;
