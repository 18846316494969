import React from 'react';
import QRCode from 'weborama.qrcode.react';

const QRCodeForm = ({ QRcodeUrl }) => {
  console.log(QRcodeUrl);
  return (
    <div>
      <QRCode style={{ alignSelf: 'center', width: '75px', height: '75px' }} id="canvas" renderAs="canvas" value={QRcodeUrl} />
    </div>
  );
};

export default QRCodeForm;
