import React from 'react';
import styles from './TemplateExamplesDataForm.module.css';
import {
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import update from 'immutability-helper';

function DataEditForm(props) {
  const { search } = useLocation();
  const { template } = queryString.parse(search);

  return (
    <div className={styles.examplesDataFormContainer}>

      <h1>Template info: {props.dataSet.template_name}</h1>
      <h3>Bullet points</h3>
      <div className={styles.bulletPointsContainer}>
        {props.dataSet.bullet_points.map((bulletPoint, i) => {
          return (
            <div key={i} className={styles.bulletPointContainer}>
              <input value={bulletPoint}
                onChange={(e) => {
                  props.setTemplatesData((prevValue) => (
                    update(prevValue, { [template]: { template_info: { bullet_points: { [i]: { $set: e.target.value } } } } })
                  ));
                }}
              ></input>

              <div className={styles.iconButtonContainer}>
                <i className="fas fa-minus" onClick={
                  () => {
                    const removeIndex = props.dataSet.bullet_points.indexOf(bulletPoint)
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { bullet_points: { $splice: [[removeIndex, 1]] } } } })
                    ));
                  }
                }></i>
              </div>
            </div>
          )
        })}

        <div
          className={styles.addElementField}
          onClick={
            () => {
              (!props.dataSet.bullet_points.includes('') &&
                props.setTemplatesData((prevValue) => (
                  update(prevValue, { [template]: { template_info: { bullet_points: { $push: [''] } } } })
                )))
            }
          }
        >
          <div className={styles.iconButtonContainer}>
            <i className="fas fa-plus"></i>
          </div>

        </div>
      </div>

      <h3>Description</h3>
      <textarea value={props.dataSet.description}
      className={styles.descriptionInput}
        onChange={(e) => {
          props.setTemplatesData((prevValue) => (
            update(prevValue, { [template]: { template_info: { description: { $set: e.target.value } } } })
          ));
        }}
      ></textarea>

      <h3>Template example:</h3>
      <div className={styles.examplesContainer}>
        {props.dataSet.examples.map((example, i) => {
          return (
            <div className={styles.examplesDiv} key={i}>
            {/* <h3>Template example:</h3> */}
              <div>
                <label>Advertiser</label>
                <input value={example.advertiser}
                  onChange={(e) => {
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { [i]: { advertiser: { $set: e.target.value } } } } } })
                    ));
                  }}></input>
              </div>

              <div>
                <label>Campaign</label>
                <input value={example.campaign}
                  onChange={(e) => {
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { [i]: { campaign: { $set: e.target.value } } } } } })
                    ));
                  }}></input>
              </div>

              <div>
                <label>Preview url</label>
                <input value={example.preview_url}
                  onChange={(e) => {
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { [i]: { preview_url: { $set: e.target.value } } } } } })
                    ));
                  }}></input>
              </div>

              <div>
                <label>Thumbnail file (Ex: banner.png)</label>
                <input value={example.thumbnail_file_name}
                  onChange={(e) => {
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { [i]: { thumbnail_file_name: { $set: e.target.value } } } } } })
                    ));
                  }}></input>
              </div>

              <div>
                <label>Video url</label>
                <input value={example.video_url}
                  onChange={(e) => {
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { [i]: { video_url: { $set: e.target.value } } } } } })
                    ));
                  }}></input>
              </div>

           <div className={styles.examplesRemoveContainer}>
              <div className={styles.iconButtonContainer}>
                <i className="fas fa-minus" onClick={
                  () => {
                    const removeIndex = props.dataSet.examples.indexOf(example)
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { examples: { $splice: [[removeIndex, 1]] } } } })
                    ));
                  }
                }></i>
              </div>
            </div>

                  {i !== props.dataSet.examples.length - 1 && <hr></hr>}
            </div>
          )
        })}

        <div
          className={styles.addElementField}
          onClick={
            () => {
              (props.dataSet.examples.map(example => Object.values(example).includes('')).every(element => element === false) &&
                props.setTemplatesData((prevValue) => (
                  update(prevValue, {
                    [template]: {
                      template_info: {
                        examples: {
                          $push: [{
                            advertiser: "",
                            campaign: "",
                            preview_url: "",
                            thumbnail_file_name: "",
                            video_url: "",
                          }]
                        }
                      }
                    }
                  })
                )))
            }
          }
        >
          <div className={styles.iconButtonContainer}>
            <i className="fas fa-plus"></i>
          </div>

        </div>
      </div>

      <h3>Questions and Answers</h3>
      <div>
        {props.dataSet.faq.map((faqObject, i) => {
          return (
            <div key={i} className={styles.faqContainer}>

              <div className={styles.faqElementsContainer}>
                <div>
                  <label>Question</label>
                  <input value={faqObject.question}
                    onChange={(e) => {
                      props.setTemplatesData((prevValue) => (
                        update(prevValue, { [template]: { template_info: { faq: { [i]: { question: { $set: e.target.value } } } } } })
                      ));
                    }}></input>
                </div>

                <div>
                  <label>Answer</label>
                  <input value={faqObject.answer}
                    onChange={(e) => {
                      props.setTemplatesData((prevValue) => (
                        update(prevValue, { [template]: { template_info: { faq: { [i]: { answer: { $set: e.target.value } } } } } })
                      ));
                    }}></input>
                </div>
              </div>

              <div className={styles.iconButtonContainer}>
                <i className="fas fa-minus" onClick={
                  () => {
                    const removeIndex = props.dataSet.faq.indexOf(faqObject)
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_info: { faq: { $splice: [[removeIndex, 1]] } } } })
                    ));
                  }
                }></i>
              </div>


            </div>


          )
        })}

        <div
          className={styles.addElementField}
          onClick={
            () => {
              (!props.dataSet.bullet_points.includes('') &&
                props.setTemplatesData((prevValue) => (
                  update(prevValue, { [template]: { template_info: { faq: { $push: [{ question: '', answer: '' }] } } } })
                )))
            }
          }
        >
          <div className={styles.iconButtonContainer}>
            <i className="fas fa-plus"></i>
          </div>

        </div>
      </div>

      <h3>Instruction pdf name</h3>
      <input value={props.dataSet.instructions_pdf} onChange={(e) => {
        props.setTemplatesData((prevValue) => (
          update(prevValue, { [template]: { template_info: { instructions_pdf: { $set: e.target.value } } } })
        ));
      }}></input>

      <h3>Template files zip name</h3>
      <input value={props.dataSet.templates_files_zip} onChange={(e) => {
        props.setTemplatesData((prevValue) => (
          update(prevValue, { [template]: { template_info: { templates_files_zip: { $set: e.target.value } } } })
        ));
      }}></input>
    </div>
  );
}

export default DataEditForm;
