import React, { useState } from 'react';
import styles from './TemplateExamples.module.css';
import ReactPlayer from 'react-player/vimeo';
import axios from 'axios';

import Modal from 'react-modal';

function TemplateExamples(props) {
  const [modalVideoUrl, setModalVideoUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  async function getImage(templateName, fileName) {
    const res = await axios.get(process.env.REACT_APP_HOST + `/serve-image/${templateName}/${fileName}`, {
      responseType: 'arraybuffer'
    });

    const blob = new Blob([res.data])
    return URL.createObjectURL(blob);
  }

  Modal.setAppElement('#root');

  function openModal() {
    props.setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    props.setStickyBarActive(true);
    props.setModalIsOpen(false);
  }

  return (
    <div className={styles.examplesContent}>
      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        contentLabel="Creative Example Modal"
        className={styles.modal}
        overlayClassName={{
          base: styles.overlayBase,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overLaybeforeClose,
        }}
      >
        <div className={styles.videoContainer}>
          <ReactPlayer
            url={modalVideoUrl}
            className={styles.reactPlayer}
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
        <div className={styles.buttonsContainer}>
          <a
            href={previewUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.previewButton}
          >
            Show Preview
          </a>

          <span className={styles.closeButton} onClick={closeModal}>
            Close
            <i className="fas fa-times"></i>
          </span>
        </div>
      </Modal>

      


      <div className={styles.examplesContainer}>
        <h1>Examples: {props.selectedFormat.replace('_', ' ')}</h1>
        {props.examples.map((example, index) => {
          return (
            <div key={index} className={styles.exampleContainer}>
              <div className={styles.exampleContent}>
                <img
                  src={`${process.env.REACT_APP_HOST}/public/template_example_assets/${props.selectedFormat}/${example.thumbnail_file_name}`}
                  alt={`${example.advertiser} ${example.campaign} creative`}
                  onClick={() => {
                    setModalVideoUrl(example.video_url);
                    setPreviewUrl(example.preview_url);
                    openModal();
                    props.setStickyBarActive(false);
                  }}
                ></img>
                <div className={styles.exampleTextContent}>
                  <div className={styles.title}>
                    <span>{example.advertiser}</span>
                  </div>
                  <div className={styles.subtitle}>{example.campaign}</div>
                  <span
                    onClick={() => {
                      setModalVideoUrl(example.video_url);
                      setPreviewUrl(example.preview_url);
                      openModal();
                      props.setStickyBarActive(false);
                    }}
                    className={styles.link}
                  >
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TemplateExamples;