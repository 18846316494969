import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Switch,
    Route,
  NavLink,
  useRouteMatch,
  useLocation,
  Redirect,
  useHistory
} from 'react-router-dom';

import queryString from 'query-string';
import update from 'immutability-helper';

import TemplateFilesManagement from '../../components/TemplateFilesManagement/TemplateFilesManagement';

import styles from './TemplateFiles.module.css';
import navStyles from '../../components/Nav.module.css';

function UploadHistory(props) {
    const { url } = useRouteMatch();
    const { search } = useLocation();
    const { template, page } = queryString.parse(search);
    const [templateFilesData, setTemplateFilesData] = useState({});


    useEffect(() => {
      axios.get(process.env.REACT_APP_HOST + '/get-file-data')
        .then(templateFileData => {
          setTemplateFilesData(templateFileData.data);
        });
    }, [])

  return (
    <div className={styles.templateFiles}>
    <div className={styles.templateFilesContent}>
    {
      Object.keys(templateFilesData).length && (
        <>
        <div className={styles.tabContainer}>
          <h2>Template Data</h2>
          <ul className={navStyles.tabList}>
            {Object.keys(templateFilesData.templates).map((templateName, key) => {
              return (
                <li key={key}>
                  <NavLink
                    to={`${url}?template=${templateName}`}
                    className={navStyles.stepTabListItem}
                    activeClassName={navStyles.active}
                    isActive={() => template == templateName}
                  >
                    {templateName.replace(/_/g, ' ')}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.tabContent}>
          {template && (
                <TemplateFilesManagement 
                selectedFormat={template}
                templateFilesData={templateFilesData}
                setTemplateFilesData={setTemplateFilesData}
                templatesData={props.templatesData[template]}
                 />               
          )
          }
        </div>
        </>
      )
    }
     
        </div>
    </div>
  );
}

export default UploadHistory;
