import React, { useState } from 'react';
import styles from './TemplatePicker.module.css';
import AnimateHeight from 'react-animate-height';
import { useHistory, useLocation } from 'react-router-dom';

function TemplatePicker(props) {
  const [selectOpen, setSelectOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  return (
    <div>
      <div className={styles.customSelectDropdown}>
        <div
          className={styles.toggleDropdownButton}
          onClick={() => {
            setSelectOpen((prevState) => !prevState);
          }}
        >
          <span className={styles.dropdownText}>
            {props.selectedFormat
              ?props.templateData[props.selectedFormat].long_template_name
              : 'Select a template'}
          </span>
          <span
            className={`${styles.dropdownArrow} } ${
              selectOpen === true ? styles.active : null
            }`}
          >
            <i className="fas fa-chevron-down"></i>
          </span>
        </div>
        <AnimateHeight duration={500} height={selectOpen ? 'auto' : 0}>
          <div
            className={`${styles.dropdownContent} ${
              selectOpen === true ? styles.open : null
            }`}
          >       
            {Object.entries(props.templateData).map(
              ([templateNameKey, templateData]) => {
              return (
                <span
                  key={templateNameKey}
                  onClick={(event) => {
                    history.push(
                      `${location.pathname}?format=${templateNameKey}`
                      );
                    setSelectOpen(false);
                  }}
                >
                  {templateData.long_template_name}
                </span>
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}

export default TemplatePicker;
