import React, { useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import StepNavigation from '../../components/StepNavigation/StepNavigation';
// eslint-disable-next-line
import TemplateExamples from '../../components/TemplateExamples/TemplateExamples';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import queryString from 'query-string';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import StepOne from '../StepOne/StepOne';
import StepTwo from '../StepTwo/StepTwo';
import StepThree from '../StepThree/StepThree';
import StepFour from '../StepFour/StepFour';

import styles from './Home.module.css';

function Home(props) {
  const [templatesData, setTemplatesData] = useState();
  const [defaultData, setDefaultData] = useState();
  const [loading, setLoading] = useState(true);

  if (!templatesData) {
    axios
      .get(process.env.REACT_APP_HOST + '/get-all-template-and-page-data')
      .then(function (response) {
        setTemplatesData(response.data.templatesData);
        setDefaultData(response.data.defaultData);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  const [creativeLink, setCreativeLink] = useState({});
  const [checkedFilesInfo, setCheckedFilesInfo] = useState({});
  const [creativeZipLink, setCreativeZipLink] = useState({});
  const [previewWasViewed, setPreviewWasViewed] = useState({});
  const [stickyBarActive, setStickyBarActive] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { pathname, search } = useLocation();
  const { format } = queryString.parse(search);

  const examplesRef = useRef(null);
  const executeScroll = () => examplesRef.current.scrollIntoView({ behavior: 'smooth' });

  // generate session id in 'App' instead of 'Home'
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', uuidv4());
  }

  const { path } = useRouteMatch();
  // form values
  const [campaignName, setCampaignName] = useState({ value: '', valid: true });
  const [contactName, setContactName] = useState({ value: '', valid: true });
  const [email, setEmail] = useState({ value: '', valid: true });
  const [ccEmails, setCcEmails] = useState([{ value: '', valid: true }]);
  const [landingPageURLs, setLandingPageURLs] = useState([{ value: '', valid: true }]);
  const [businessUnit, setBusinessUnit] = useState({
    country_english: 'Netherlands',
    country_native: 'Nederland',
    country_shorthand: 'NL',
    email: 'sales@weborama.nl',
  });
  const [comment, setComment] = useState('');

  return (
    <div className={`${styles.homeContainer}`}>
      <Header />
      <div className={`${styles.body} ${isMobile && styles.mobile}`}>
        {!pathname.includes('stepFour') && <StepNavigation />}

        <div className={styles.stepContent}>
          {loading ? (
            <div className={styles.loadContainer}>
              <div className={styles.loadingIcon}></div>
            </div>
          ) : (
            <Switch>
              <Route path={`${path}/stepOne`}>
                <StepOne
                  templatesData={templatesData}
                  introductionData={format ? templatesData[format].template_info : defaultData.home_page}
                  selectedFormat={format}
                  goToExamples={executeScroll}
                  stickyBarActive={stickyBarActive}
                  setStickyBarActive={setStickyBarActive}
                  modalIsOpen={modalIsOpen}
                />
              </Route>
              <Route path={`${path}/stepTwo`}>
                <StepTwo
                  templatesData={templatesData}
                  selectedFormat={format}
                  creativeLink={creativeLink[format]}
                  setCreativeLink={setCreativeLink}
                  checkedFilesInfo={checkedFilesInfo[format]}
                  setCheckedFilesInfo={setCheckedFilesInfo}
                  previewWasViewed={previewWasViewed}
                  setPreviewWasViewed={setPreviewWasViewed}
                  setCreativeZipLink={setCreativeZipLink}
                />
              </Route>
              <Route path={`${path}/stepThree`}>
                <StepThree
                  selectedFormat={format}
                  creativeLink={creativeLink[format]}
                  campaignName={campaignName}
                  setCampaignName={setCampaignName}
                  contactName={contactName}
                  setContactName={setContactName}
                  email={email}
                  setEmail={setEmail}
                  ccEmails={ccEmails}
                  setCcEmails={setCcEmails}
                  businessUnit={businessUnit}
                  landingPageURLs={landingPageURLs}
                  setLandingPageURLs={setLandingPageURLs}
                  setBusinessUnit={setBusinessUnit}
                  comment={comment}
                  setComment={setComment}
                  creativeZipLink={creativeZipLink[format]}
                  defaultData={defaultData.contact_page}
                />
              </Route>
              <Route path={`${path}/stepFour`}>
                <StepFour />
              </Route>
            </Switch>
          )}
        </div>
        {/* Render examples only when link
      contains step one and format has been selected */}
        {pathname.includes('stepOne') && format && templatesData && (
          <div ref={examplesRef}>
            <TemplateExamples
              examples={templatesData[format].template_info.examples}
              selectedFormat={format}
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              setStickyBarActive={setStickyBarActive}
            />{' '}
          </div>
        )}
      </div>
      <Footer stickyBarActive={stickyBarActive} setStickyBarActive={setStickyBarActive} />
    </div>
  );
}

export default Home;
