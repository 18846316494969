import React from 'react';
import styles from './TemplateFilesDataForm.module.css';
import {
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import update from 'immutability-helper';

function DataEditForm(props) {
  const { search } = useLocation();
  const { template } = queryString.parse(search);

  return (
    <div className={styles.filesDataFormContainer}>

      <div className={styles.formFieldsContainer}>
        <h2>File data</h2>
        <div className={styles.inputContainer}>
          <label htmlFor={'formatId'}>ID</label>
          <input value={props.dataSet._id} name={'formatId'} id={'formatId'} disabled></input>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.inputContainer}>
            <label htmlFor={'templateName'}>Short template name</label>
            <input value={props.dataSet.template_name} name={'templateName'} id={'templateName'} onChange={(e) => {
              // Remove spaces that potentially have been pasted
              const value = e.target.value.replace(/\s/g, "");

              props.setTemplatesData((prevValue) => (
                update(prevValue, { [template]: { template_name: { $set: value } } })
              ));
            }}
            onKeyDown={(e) => {
              // Check if a space is entered and if so stop the action
              e.key === " " && e.preventDefault()
            }
            }
            ></input>
          </div>



          <div className={styles.inputContainer}>
            <label htmlFor={'longName'}>Long template name</label>
            <input value={props.dataSet.long_template_name} name={'longName'} id={'longName'}
              onChange={(e) => {
                props.setTemplatesData((prevValue) => (
                  update(prevValue, { [template]: { long_template_name: { $set: e.target.value } } })
                ));
              }}
            ></input>
          </div>
        </div>

        <div className={styles.elementFieldsContainer}>
          <h3>Elements</h3>
          {props.dataSet.template_elements &&
            props.dataSet.template_elements.map((element, i) => {
              return (
                <div className={styles.elementInputsContainer} key={i}>
                  <input
                    type="text"
                    name="elementFiles"
                    value={element}
                    // className={}
                    onChange={
                      (e) => props.setTemplatesData((prevValue) => (
                        update(prevValue, { [template]: { template_elements: { [i]: { $set: e.target.value } } } })
                      ))
                    }
                  />
                  <div className={styles.iconButtonContainer}>
                    <i className="fas fa-minus" onClick={
                      () => {
                        props.setTemplatesData((prevValue) => ({
                          ...prevValue,
                          [template]: {
                            ...prevValue[template],
                            template_elements: prevValue[template].template_elements.filter(arrayElement => arrayElement != element)
                          }
                        }));

                        // After removing it from the arary also remove the value from all files that had it
                        Object.entries(props.dataSet.template_files).map(
                          ([fileName, properties]) => {
                            if (properties.template_element) {
                              const removeIndex = properties.template_element.indexOf(element)
                              if (properties.template_element && removeIndex != -1) {
                                props.setTemplatesData((prevValue) => (
                                  update(prevValue, { [template]: { template_files: { [fileName]: { template_element: { $splice: [[removeIndex, 1]] } } } } })
                                ))
                              }
                            }

                          })
                      }
                    }></i>
                  </div>
                </div>
              );
            })}

          <div
            className={styles.addElementField}
            onClick={
              () => {
                !props.dataSet.template_elements ? (
                  props.setTemplatesData((prevValue) => (
                    update(prevValue, { [template]: { $merge: { 'template_elements': [''] } } })
                  ))
                ) : (!props.dataSet.template_elements.includes('') &&
                  props.setTemplatesData((prevValue) => (
                    update(prevValue, { [template]: { template_elements: { $push: [''] } } })
                  )))


              }
            }
          >
            <div className={styles.iconButtonContainer}>
              <i className="fas fa-plus"></i>
            </div>

          </div>
        </div>

      </div>

             {/* <div className={styles.inputGroup}>
          <div className={styles.inputContainer}>
            <label htmlFor={'templateName'}>Short template name</label>
            <input value={props.dataSet.template_name} name={'templateName'} id={'templateName'} onChange={(e) => {
              // Remove spaces that potentially have been pasted
              const value = e.target.value.replace(/\s/g, "");

              props.setTemplatesData((prevValue) => (
                update(prevValue, { [template]: { template_name: { $set: value } } })
              ));
            }}
            onKeyDown={(e) => {
              // Check if a space is entered and if so stop the action
              e.key === " " && e.preventDefault()
            }
            }
            ></input>
          </div> */}


      {Object.entries(props.dataSet.template_files).map(
        ([fileName, properties], i) => {
          return (
            <div key={i} className={styles.formFieldsContainer}>
              <h3>File info</h3>
              <div className={styles.inputContainer}>
                <label htmlFor={`file-${i}`}>File name:</label>
                <input value={fileName} name={`file-${i}`} id={`file-${i}`}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\./g, "");

                    props.setTemplatesData((prevValue) => (
                      update(prevValue, {
                        [template]: {
                          template_files: obj => {
                            const newObject = {};
                            Object.keys(obj).map(key => {
                              if (key === fileName) {
                                newObject[value] = obj[key];
                              } else {
                                newObject[key] = obj[key];
                              }
                            })
                            return newObject;
                          }
                        }
                      })
                    ));
                  }}
                  onKeyDown={(e) => {
              // Check if a space is entered and if so stop the action
              e.key === "." && e.preventDefault()
            }}
                  
                ></input>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor={`fileType-${i}`}>File extension</label>
                <select name={`fileType-${i}`} id={`fileType-${i}`} value={properties.file_type} onChange={(e) =>
                  props.setTemplatesData((prevValue) => (
                    update(prevValue, { [template]: { template_files: { [fileName]: { file_type: { $set: e.target.value } } } } })
                  ))
                }>
                  <option value="png">PNG</option>
                  <option value="jpg">JPG</option>
                  <option value="mp4">MP4</option>
                  <option value="jpeg">JPEG</option>
                  <option value="mp3">MP3</option>
                </select>
              </div>

              <h3>Dimensions</h3>
              <div className={styles.inputContainer}>
                <label htmlFor={`checkExactDimensions-${i}`}>Check exact dimentions?</label>
                <select value={properties.dimensions.check_exact_dimensions} name={`checkExactDimensions-${i}`} id={`checkExactDimensions-${i}`}
                  onChange={(e) =>
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_files: { [fileName]: { dimensions: { check_exact_dimensions: { $set: e.target.value } } } } } })
                    ))
                  }>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor={`maxFileSize-${i}`}>Max file size (KB)</label>
                <input value={properties.max_file_size} name={`maxFileSize-${i}`} id={`maxFileSize-${i}`}
                  onChange={(e) =>
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_files: { [fileName]: { max_file_size: { $set: e.target.value } } } } })
                    ))
                  }></input>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor={`height-${i}`}>Height (PX)</label>
                <input value={properties.dimensions.height} name={`height-${i}`} id={`height-${i}`}
                  onChange={(e) =>
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_files: { [fileName]: { dimensions: { height: { $set: e.target.value } } } } } })
                    ))
                  }></input>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor={`width-${i}`}>Width (PX)</label>
                <input value={properties.dimensions.width} name={`width-${i}`} id={`width-${i}`}
                  onChange={(e) =>
                    props.setTemplatesData((prevValue) => (
                      update(prevValue, { [template]: { template_files: { [fileName]: { dimensions: { width: { $set: e.target.value } } } } } })
                    ))
                  }></input>
              </div>

              {(props.dataSet.template_elements && props.dataSet.template_elements.length > 0) &&
                <div className={styles.checkBoxesContainer}>
                  <h3>Part of elements:</h3>
                  {
                    props.dataSet.template_elements.map((element, i) => {
                      return element && (
                        <div key={i} className={styles.checkBoxContainer}>
                          <label>{element.replaceAll('_', ' ')}
                            <input type="checkbox" value={element} name={`element-${i}-${i}`} id={`element-${i}-${i}`}
                              checked={(properties.template_element && properties.template_element.length > 0 && properties.template_element.includes(element))}
                              onChange={(e) =>
                                props.setTemplatesData((prevValue) => (
                                  properties.template_element.includes(element) ?
                                    update(prevValue, {
                                      [template]: {
                                        template_files: {
                                          [fileName]: {
                                            template_element: {
                                              $splice: [[properties.template_element.indexOf(element), 1]]
                                            }
                                          }
                                        }
                                      }
                                    })
                                    :
                                    update(prevValue, { [template]: { template_files: { [fileName]: { template_element: { $push: [e.target.value] } } } } })
                                ))
                              }
                            ></input>
                            <span className={styles.checkmark}></span>
                          </label>

                        </div>
                      )
                    })
                  }
                </div>
              }

              <div className={styles.plusButtonContainer}>
                <div
                  className={styles.addElementField}
                  onClick={
                    () => {
                        props.setTemplatesData((prevValue) => (
                          update(prevValue, {
                            [template]: {
                              template_files: {
                                $merge: {
                                  "": {
                                    "template_element": [],
                                    "file_type": "png",
                                    "dimensions": {
                                      "check_exact_dimensions": false,
                                      "width": 0,
                                      "height": 0
                                    },
                                    "max_file_size": 0
                                  }
                                }
                              }
                            }
                          })
                        ))
                    }
                  }
                >
                  <div className={styles.iconButtonContainer}>
                    <i className="fas fa-plus"></i>
                  </div>

                </div>
              </div>

              {i !== props.dataSet.template_files.length - 1 && <hr></hr>}
            </div>
          );

        })}

    </div>
  );
}

export default DataEditForm;
