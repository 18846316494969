import React from 'react';
import styles from './Introduction.module.css';

function Introduction(props) {
  return (
    <div className={styles.introductionDiv}>
      <p className={styles.introductionText}>{props.introduction}</p>
      {props.bulletPoints.map((bulletPoint, index) => {
        return (
          <div key={index} className={styles.bulletPoint}>
            <span className={styles.bullet}>
              <i className="fas fa-check"></i>
            </span>
            <span className={styles.point}>{bulletPoint}</span>
          </div>
        );
      })}
    </div>
  );
}

export default Introduction;
