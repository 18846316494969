import React from 'react';
import styles from './FileResults.module.css';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import QRCodeForm from '../../QRcode';

function FileResults(props) {
  const history = useHistory();

  return (
    <div>
      <div>
        <div className={styles.resultsHeaderContainer}>
          <span>Asset File</span> <span>Validation check</span>
        </div>

        <div className={styles.resultsContainer}>
          {props.checkedFilesInfo
            ? props.checkedFilesInfo.map((file, index) => {
                return (
                  <div key={index} className={styles.resultContainer}>
                    <span className={styles.fileName}>{`${file.fileName}.${file.fileExtension}`}</span>

                    {file.validationErrors.length > 0 ? (
                      <span className={styles.error}>
                        {file.validationErrors.map((error, index) => {
                          return <div key={index}>{error}</div>;
                        })}
                      </span>
                    ) : (
                      <span className={styles.accepted}>Accepted</span>
                    )}
                  </div>
                );
              })
            : Object.entries(props.formatRequirements).map((formatsArr, index) => {
                return (
                  <div key={index} className={styles.resultContainer}>
                    <span className={styles.fileName}>{`${formatsArr[0]}.${formatsArr[1].file_type}`}</span>

                    <span className={styles.notUploaded} disabled={props.selectedFormat}>
                      Not uploaded yet
                    </span>
                  </div>
                );
              })}
        </div>

        <div className={styles.nextStepsButtonContainers}>
          {props.creativeLink && <QRCodeForm QRcodeUrl={props.creativeLink} />}{' '}
          <a
            href={props.creativeLink}
            className={`${styles.previewButton}
            ${!props.creativeLink && styles.disabled}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              if (props.creativeLink) {
                props.setPreviewWasViewed((prevValue) => ({
                  ...prevValue,
                  [props.selectedFormat]: true,
                }));
              } else {
                toast.warn('Previewing unavailable until all files are accepted');
              }
            }}
          >
            <span>Preview Creative</span>
          </a>
          <div
            className={`${styles.notifyButton}
              ${!props.previewWasViewed[props.selectedFormat] && styles.disabled}`}
            onClick={() => {
              if (props.previewWasViewed[props.selectedFormat]) {
                history.push(`/home/stepThree?format=${props.selectedFormat}`);
              } else {
                toast.warn('Please preview your creative before continuing');
              }
            }}
          >
            <span>Notify Weborama</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileResults;
