import React from 'react';
import { isMobile } from 'react-device-detect';
import styles from './Footer.module.css';
import { useLocation } from 'react-router-dom';

function Footer(props) {
  const location = useLocation();
  let expansion = '';

  if (props.stickyBarActive && location.pathname.split('/')[2] === 'stepOne') {
    expansion = styles.expanded;
  }

  return (
    <footer
      className={`${styles.footer} ${expansion} ${isMobile && styles.mobile}`}
    >
      <div className={styles.footerContent}>
        <a
          href="https://www.weborama.nl/en/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>{' '}
        <a
          href="https://www.weborama.nl/en/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Statement
        </a>{' '}
        <span>Product of Weborama Netherlands 2021</span>
      </div>{' '}
    </footer>
  );
}

export default Footer;
