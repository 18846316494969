import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  NavLink,
  Redirect,
} from 'react-router-dom';
import styles from './StepOne.module.css';
import navStyles from '../../components/Nav.module.css';
import TemplatePicker from '../../components/TemplatePicker/TemplatePicker';
import Introduction from '../../components/Tabs/Introduction/Introduction';
import Specifications from '../../components/Tabs/Specification/Specifications';
import Faq from '../../components/Tabs/Faq/Faq';
//eslint-disable-next-line
import DownloadLinksLarge from '../../components/DownLoadLinksLarge/DownloadLinksLarge';
import StickyBar from '../../components/StickyBar/StickyBar';

function StepOne(props) {
  const history = useHistory();
  const { path, url } = useRouteMatch();

  return !isMobile ? (
    <div className={styles.pageOne}>
      <div className={styles.tabContainer}>
        <ul className={navStyles.tabList}>
          {props.selectedFormat ? (
            <>
              <li>
                <NavLink
                  to={`${url}/introduction?format=${props.selectedFormat}`}
                  className={navStyles.stepTabListItem}
                  activeClassName={navStyles.active}
                >
                  Introduction
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${url}/specifications?format=${props.selectedFormat}`}
                  className={navStyles.stepTabListItem}
                  activeClassName={navStyles.active}
                >
                  Specifications
                </NavLink>
              </li>
              <li
                onClick={() => {
                  props.goToExamples();
                }}
              >
                <span className={`${navStyles.stepTabListItem}`}>Examples</span>
              </li>
              <li>
                <NavLink
                  to={`${url}/faq?format=${props.selectedFormat}`}
                  className={navStyles.stepTabListItem}
                  activeClassName={navStyles.active}
                >
                  FAQ
                </NavLink>
              </li>
            </>
          ) : (
            <li>
              <NavLink
                to={`${url}/introduction`}
                className={navStyles.stepTabListItem}
                activeClassName={navStyles.active}
              >
                Introduction
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div className={styles.tabContent}>
        <h1>Start creating your own creative</h1>
        <TemplatePicker
          templateData={props.templatesData}
          selectedFormat={props.selectedFormat}
        />
        {props.selectedFormat ? (
          <DownloadLinksLarge
            inSideBar={false}
            selectedFormat={props.selectedFormat}
            instructionsPdf={
              props.templatesData[props.selectedFormat].template_info
                .templates_files_zip
            }
            templateFilesZip={
              props.templatesData[props.selectedFormat].template_info
                .instructions_pdf
            }
          />
        ) : null}
        <Switch>
          <Route exact path={path}>
            <Redirect from={path} to={`${path}/introduction/`} />
          </Route>
          <Route path={`${path}/introduction`}>
            <Introduction
              introduction={props.introductionData.description}
              bulletPoints={props.introductionData.bullet_points}
            />
          </Route>
          <Route path={`${path}/specifications`}>
            <Specifications
              requiredFiles={
                props.selectedFormat
                  ? props.templatesData[props.selectedFormat].template_files
                  : undefined
              }
            />
          </Route>
          <Route path={`${path}/faq`}>
            <Faq
              faqData={
                props.selectedFormat
                  ? props.templatesData[props.selectedFormat].template_info.faq
                  : undefined
              }
            />
          </Route>
        </Switch>
      </div>

      <div className={styles.rightBar}>
        <button
          onClick={() => {
            history.push(`/home/stepTwo?format=${props.selectedFormat}`);
          }}
          className={styles.selectTemplateButton}
          disabled={!props.selectedFormat}
        >
          Start uploading
        </button>
      </div>

      <StickyBar
        stickyBarActive={props.stickyBarActive}
        selectedFormat={props.selectedFormat}
        setStickyBarActive={props.setStickyBarActive}
        modalIsOpen={props.modalIsOpen}
      />
    </div>
  ) : (
    <div className={styles.mobileContentContainer}>
      <h1>Start creating your own creative</h1>

      <p className={styles.introductionText}>
        {props.introductionData.description}
      </p>

      <div className={styles.bulletPointsContainer}>
        {props.introductionData.bullet_points.map((bulletPoint, index) => {
          return (
            <div key={index} className={styles.bulletPoint}>
              <span className={styles.bullet}>
                <i className="fas fa-check"></i>
              </span>
              <span className={styles.point}>{bulletPoint}</span>
            </div>
          );
        })}
      </div>

      <div className={styles.mobileWarning}>
        Please visit this page on desktop to continue
      </div>
    </div>
  );
}

export default StepOne;
