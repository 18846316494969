import './App.css';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './customToast.css';
import { isMobile } from 'react-device-detect';
import Home from './Pages/HomePage/Home';
import Admin from './Pages/AdminPage/Admin';

import React from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className={`App ${isMobile && 'mobile'}`}>
      <Router>
        <Switch>
          <Redirect from="/" to="/home/stepOne/" exact />
          {/* <Redirect from="/stepOne" to="/stepOne/introduction" exact />  */}
          <Route path="/home">
            <Home />
          </Route>

          <Redirect from="/admin" to="/admin/upload-history/" exact />
          <Route path="/admin">
            <Admin />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
