import React from 'react';
import styles from './StepNavigation.module.css';
import { useLocation } from 'react-router-dom';

function StepNavigation(props) {
  const steps = {
    1: 'Select Creative',
    2: 'Create & preview',
    3: 'Submit creative',
  };

  const location = useLocation();

  function checkStep() {
    switch (location.pathname.split('/')[2]) {
      case 'stepOne':
        return '1';
      case 'stepTwo':
        return '2';
      case 'stepThree':
        return '3';
      default:
        return '0';
    }
  }

  return (
    <div className={styles.stepNavigation}>
      <h1>Easy Rich Media</h1>
      {Object.keys(steps).map((key) => {
        return (
          <span
            className={`${styles.stepContainer} 
               ${key === checkStep() ? styles.active : null}`}
            key={key}
          >
            <div className={styles.numberContainer}>
              <span>{key}</span>
            </div>
            <div className={styles.textContainer}>
              <span className={styles.text}>{steps[key]}</span>
            </div>
          </span>
        );
      })}
    </div>
  );
}

export default StepNavigation;
