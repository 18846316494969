import React, { useState } from 'react';
import styles from './ConfirmationButton.module.css';

function ConfirmationButton(props) {
  const [confirmationActive, setConfirmationActive] = useState(false);

  return (
    <div className={styles.buttonsContainer}>
      {confirmationActive ? (
        <>
          <span
            className={`${styles.button} ${styles.confirm}`}
            onClick={() => {
              props.buttonAction();
            }}
          >
            <i className="fas fa-check"></i>
          </span>
          <span
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => {
              setConfirmationActive(false);
            }}
          >
            <i className="fas fa-times"></i>
          </span>
        </>
      ) : (
        <span
          className={`${styles.button} ${styles.initial}`}
          onClick={() => {
            setConfirmationActive(true);
          }}
        >
          {props.buttonText}
        </span>
      )}
    </div>
  );
}

export default ConfirmationButton;
